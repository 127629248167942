import { ReactComponent as MainIcon } from "assets/page-header-items/main-item-yellow.svg";
import { ImageFrame } from "components/ui/ImageFrame";

import FrameImg from "assets/img/front/showcase_1.jpeg";
import TeamImg from "assets/img/our-team.webp";
import Contacts from "components/sections/Contacts";
import LogoLasNaves from "assets/img/partners/logo-las-naves.webp";
import LogoAyuntamiento from "assets/img/partners/logo-ayuntamiento-valencia.svg";
import LogoSIPAM from "assets/img/partners/logo-sipam.svg";
import LogoMissionsValencia2030 from "assets/img/partners/logo-missions-valencia-2030.webp";

import LogoEu from "assets/img/partners/logo-eu.webp";
import LogoGob from "assets/img/partners/logo-gob.webp";
import LogoGeneralitatValenciana from "assets/img/partners/logo-generalitat-valenciana.svg";
import LogoRPTR from "assets/img/partners/logo-rptr.webp";
import LogoIVACE from "assets/img/partners/logo-ivace.webp";

import AnimatedTabs, { type TabsItem } from "components/molecules/AnimatedTabs";
// import OurTeam from "components/sections/OurTeam";
import OurTeamCompact from "components/sections/OurTeamCompact";

const AboutUs = () => {
  const tabsItems: TabsItem[] = [
    {
      icon: LogoEu,
      key: "eu",
      name: "Unión Europea",
      link: "https://europa.eu",
      content:
        "Cidaqua es un proyecto financiado por la Unión Europea a través del programa Horizonte 2020. Este programa de investigación e innovación apoya proyectos que abordan los desafíos sociales y ambientales más urgentes de Europa.",
    },
    // {
    //   icon: LogoLasNaves,
    //   key: "lasnaves",
    //   name: "Las Naves",
    //   link: "https://www.lasnaves.com",
    //   content:
    //     "Cidaqua fue creado dentro de Col·lab, la aceleradora pública de Las Naves, el centro de innovación del Ayuntamiento de Valencia. Esta colaboración nos proporciona acceso a recursos, experiencia y redes que mejoran el impacto y alcance del proyecto.",
    // },
    // {
    //   icon: LogoAyuntamiento,
    //   key: "ayuntamiento",
    //   name: "Ayuntamient de València",
    //   link: "https://valenciamediterraneo.es",
    //   content:
    //     "El “Tribunal de las Aguas de la Vega de Valencia”, más conocido por su denominación abreviada de “Tribunal de las Aguas” es, sin duda alguna, la más antigua de las instituciones de justicia existentes en Europa. Institución a la que vamos a ofrecer nuestro producto disruptivo e innovador para modernizar la gestión del agua de riego y ser una institución pionera en el mundo, aunando impactos ambientales, económicos y sociales. De esta forma podrá tener un control mas profundo y preciso de la gestión del consumo del agua de riego cara a aumentar el ahorro y optimizar las infraestructuras históricas.",
    // },
    {
      icon: LogoSIPAM,
      key: "sipam",
      name: "Sistema de Información de Patrimonio Agrícola Mundial",
      link: "https://www.fao.org/giahs/es/",
      content:
        "El Sistema de Información de Patrimonio Agrícola Mundial (SIPAM) es una iniciativa de la Organización de las Naciones Unidas para la Alimentación y la Agricultura (FAO) que tiene como objetivo promover y proteger el patrimonio agrícola mundial. SIPAM trabaja en estrecha colaboración con comunidades agrícolas, expertos y organizaciones internacionales para identificar, documentar y conservar las prácticas agrícolas tradicionales y sostenibles que son fundamentales para la seguridad alimentaria y la preservación de la biodiversidad. Cidaqua se enorgullece de ser parte de SIPAM y contribuir a la preservación de la huerta de Valencia y sus técnicas agrícolas tradicionales.",
    },
    {
      icon: LogoMissionsValencia2030,
      key: "missions",
      name: "Missions Valencia 2030",
      link: "https://www.missionsvalencia.eu/?lang=en",
      content:
        "UNESCO Valencia Centro del Mediterráneo es una institución asociada a la Organización de las Naciones Unidas para la Educación, la ciencia y la Cultura. Responsable por la inclusión del Tribunal de las Aguas en la Lista Representativa del Patrimonio Cultural Inmaterial de la Humanidad.",
    },
  ];
  return (
    <div className="flex flex-col w-full">
      <section className="h-fit w-screen  overflow-x-clip flex  flex-col md:flex-row relative  py-20">
        <div className="w-full justify-start md:justify-center  flex h-full gap-y-10 flex-col p-4">
          <div className="flex-col-reverse justify-start items-start gap-y-2 flex">
            <h1 className=" text-yellow-600  font-bold uppercase leading-7">
              Sobre nosotros
            </h1>
          </div>
          {/* <ul className="list-inside text-lg list-disc grid grid-rows-3 md:grid-cols-3 md:grid-rows-1 gap-4 md:gap-6">
            <li className="flex flex-col">
              <span className="text-base">
                Un mundo sostenible y equitativo para las generaciones futuras
              </span>
            </li>
            <li className="flex flex-col">
              <span className="text-base">
                Preservar el patrimonio cultural y natural de la Huerta del
                mundo
              </span>
            </li>
            <li className="flex flex-col">
              <span className="text-base">
                Combatear el cambio climático y la escasez de agua en la región
              </span>
            </li>
          </ul> */}
        </div>
      </section>
      <section className="bg-yellow-100 dark:bg-yellow-800 ">
        <div className="">
          <h2 className="text-center">Antecedentes e historia de Cidaqua</h2>

          <ImageFrame className=" w-full max-h-96" src={FrameImg} alt="Frame" />
          {/* Mail: hola@cidaqua.com */}
          <p>
            Cidaqua fue fundado en 2024 con la visión de abordar los desafíos
            urgentes de la gestión del agua y la preservación del patrimonio en
            la Huerta de Valencia. El proyecto surgió como respuesta al
            creciente impacto del cambio climático, que amenaza la
            sostenibilidad de los recursos hídricos de la región y pone en
            riesgo el patrimonio cultural y natural.
          </p>
        </div>
      </section>
      <section>
        <div>
          <h2>Colaboración con Empresas y Entidades Líderes</h2>
          <p>
            Cidaqua se enorgullece de colaborar con varias empresas y entidades
            prominentes que comparten nuestro compromiso con la innovación y la
            sostenibilidad. Nuestras asociaciones incluyen:
          </p>
          {/* <AnimatedTabs items={tabsItems} /> */}

          <div className="flex gap-y-4 w-full flex-col space-x-2 justify-between">
            <h4 className="text- center">Financiado por</h4>
            <div className="flex items-center justify-start center flex-col md:flex-row gap-y-2 md:gap-y-0 md:gap-x-4 w-full">
              <img
                src={LogoEu}
                alt="Logo de la Unión Europea"
                className="h-12 w-auto md:h-16 object-contain "
              />
              <img
                src={LogoGob}
                alt="Logo de la Unión Europea"
                className="h-12 w-auto md:h-16 object-contain "
              />
              <img
                src={LogoRPTR}
                alt="Logo de la Unión Europea"
                className="h-12 w-auto md:h-16 object-contain "
              />
            </div>

            <h4 className="text- center">En colaboración con</h4>
            <div className="flex items-center justify-start center flex-col md:flex-row gap-y-2 md:gap-y-0 md:gap-x-4 w-full">
              <img
                src={LogoGeneralitatValenciana}
                alt="Logo de la Unión Europea"
                className="h-12 w-auto md:h-16 object-contain"
              />
              <img
                src={LogoIVACE}
                alt="Logo de la Unión Europea"
                className="h-12 w-auto md:h-16 object-contain"
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <h2>Miembros del equipo y su experiencia</h2>
          <p>
            En Cidaqua, nuestro equipo está formado por profesionales altamente
            capacitados con diversos antecedentes y experiencia en los campos de
            gestión del agua, preservación del patrimonio, tecnología y
            sostenibilidad. Algunos miembros clave del equipo incluyen:
          </p>
          <OurTeamCompact />
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
