import { ReactComponent as MainIcon } from "assets/page-header-items/main-item-blue.svg";

// import AnimatedAccordionItem from 'components/molecules/AnimatedAccordionItem';
import AnimatedAccordionItem from "components/molecules/AnimatedAccordionItem";
import Contacts from "components/sections/Contacts";
import {ImageFrame} from "components/ui/ImageFrame";
import FrameImg from "assets/img/water-management-hero.webp";

const WaterManagement: React.FC = () => {
  const accordionImportance = [
    {
      title: "Sostenibilidad agrícola",
      content:
        "La Huerta de Valencia es reconocida por sus cultivos de frutas, hortalizas y arroz, que dependen en gran medida del suministro adecuado de agua. Una gestión eficiente del agua garantiza la disponibilidad constante de este recurso vital para mantener la productividad agrícola a largo plazo.",
      icon: "",
    },
    {
      title: "Preservación del ecosistema",
      content:
        "La Huerta de Valencia alberga una gran diversidad de especies vegetales y animales, incluidas aves migratorias y especies autóctonas. La gestión adecuada del agua contribuye a mantener los ecosistemas naturales, los humedales y los espacios verdes asociados con la huerta, asegurando un equilibrio ecológico y protegiendo la biodiversidad.",
      icon: "",
    },
    {
      title: "Protección del patrimonio cultural",
      content:
        "La Huerta de Valencia posee un valioso patrimonio cultural, en particular, las tradicionales infraestructuras hidráulicas que han sido utilizadas durante siglos para el riego de los cultivos. Una gestión adecuada del agua es fundamental para preservar estas estructuras históricas y mantener viva la historia y cultura de la región.",
      icon: "",
    },
    {
      title: "Adaptación al cambio climático",
      content:
        "El cambio climático está generando desafíos adicionales en términos de disponibilidad de agua en la Huerta de Valencia. Las sequías prolongadas y los patrones climáticos cambiantes afectan la cantidad y calidad del agua disponible. Una gestión eficiente del agua ayuda a enfrentar estos desafíos y garantiza la continuidad de la actividad agrícola y la conservación del patrimonio frente a los impactos del cambio climático.",
      icon: "",
    },
    {
      title: "Uso responsable de los recursos",
      content:
        "La gestión eficiente del agua implica utilizar este recurso de manera responsable y sostenible. Esto incluye la implementación de técnicas de riego eficientes, la promoción de prácticas agrícolas sostenibles y la concienciación sobre el uso responsable del agua entre los agricultores y la comunidad en general.",
      icon: "",
    },
  ];

  const accordionSolutions = [
    {
      title: "Tecnología de riego inteligente",
      content:
        "La aplicación de tecnologías avanzadas en sistemas de riego permite un uso preciso y eficiente del agua. Sensores de fibra óptica con medición cuantitativa y cualitativa y sistemas de riego automatizados ayudan a determinar las necesidades hídricas de los cultivos y ajustar el riego en consecuencia, evitando el desperdicio de agua.",
      icon: "",
    },
    {
      title: "Sistema predictivo de riego en función de las necesidades de riego",
      content:
        "El uso del Cálculo de Necesidades de Riego pone al alcance la metodología más extendida, sencilla y accesible para el cálculo de necesidades de riego. Se trata del método propuesto por la Organización de las Naciones Unidas para la Alimentación y la Agricultura (FAO) que tiene su base en la publicación Evapotranspiración del cultivo - Guías para la determinación de los requerimientos de agua de los cultivos.",
      icon: "",
    },
    {
      title: "Gestión basada en datos y análisis",
      content:
        "La recopilación y el análisis de datos relacionados con la gestión del agua permiten tomar decisiones informadas y optimizar los recursos disponibles. Mediante el uso de plataformas digitales y sistemas de información, se puede monitorear y analizar en tiempo real el uso del agua, identificar áreas de mejora y tomar medidas correctivas de manera oportuna.",
      icon: "",
    },
  ];

  const accordionSistemas = [
    {
      title: "Sensores y estaciones de monitoreo",
      content:
        "Se instalan sensores en diferentes puntos estratégicos de la Huerta de Valencia, como fuentes de agua, canales de riego y campos de cultivo. Estos sensores recopilan datos sobre el nivel de agua, la calidad del agua, la temperatura y otros parámetros relevantes. Las estaciones de monitoreo recopilan y procesan estos datos, proporcionando información en tiempo real sobre el estado de los recursos hídricos.",
      icon: "",
    },
    {
      title: "Control automatizado",
      content:
        "Los sistemas de control en tiempo real permiten ajustar automáticamente los flujos de agua y los ciclos de riego en función de las necesidades reales de los cultivos y las condiciones climáticas. Esto garantiza que se suministre la cantidad de agua adecuada en el momento adecuado, evitando tanto el desperdicio como la escasez de agua.",
      icon: "",
    },
    {
      title: "Alertas y alarmas",
      content:
        "Los sistemas de monitoreo en tiempo real están equipados con alertas y alarmas que se activan cuando se detectan condiciones anormales, como fugas de agua, niveles bajos de agua o problemas de calidad del agua. Estas alertas permiten una respuesta rápida y eficiente, evitando pérdidas significativas y minimizando el impacto en los cultivos y el entorno.",
      icon: "",
    },
    {
      title: "Visualización y análisis de datos",
      content:
        "Los datos recopilados por los sistemas de monitoreo en tiempo real se presentan en paneles de control intuitivos y visuales. Esto permite a los gestores de agua y agricultores acceder a información clara y comprensible sobre el estado de los recursos hídricos. Además, se pueden realizar análisis y seguimiento a largo plazo para identificar patrones y tendencias, lo que facilita la toma de decisiones informadas y la planificación a largo plazo.",
      icon: "",
    },
    {
      title: "Eficiencia en el mantenimiento",
      content:
        "Los sistemas de monitoreo en tiempo real también ayudan a optimizar el mantenimiento de la infraestructura de agua. Al detectar de manera temprana posibles fallas o problemas, se pueden tomar medidas preventivas y programar el mantenimiento de manera más eficiente, reduciendo los costos y minimizando las interrupciones en el suministro de agua.",
      icon: "",
    },
  ];

  const accordionMonitor = [
    {
      title: "Análisis de la calidad del agua",
      content:
        "Se realizan análisis periódicos de la calidad del agua en diferentes puntos de la Huerta de Valencia, incluyendo fuentes de agua, canales de riego y pozos. Estos análisis evalúan parámetros como el pH, la concentración de nutrientes, la presencia de contaminantes y otros indicadores de la calidad del agua. Esto permite detectar cualquier cambio o problema en la calidad del agua y tomar medidas correctivas de manera oportuna.",
      icon: "",
    },
    {
      title: "Monitoreo continuo",
      content:
        "Se implementan sistemas de monitoreo continuo que permiten obtener datos en tiempo real sobre la calidad del agua. Estos sistemas utilizan sensores y tecnologías avanzadas para medir diferentes parámetros, como la turbidez, la conductividad eléctrica y la presencia de contaminantes específicos. El monitoreo continuo ayuda a identificar tendencias, cambios estacionales y eventos anómalos que podrían afectar la calidad del agua.",
      icon: "",
    },
    {
      title: "Control de fuentes de contaminación",
      content:
        "Se implementan medidas para controlar y reducir las fuentes de contaminación que pueden afectar la calidad del agua en la Huerta de Valencia. Esto incluye la implementación de prácticas agrícolas sostenibles, la promoción del uso responsable de productos químicos y fertilizantes, y la regulación de actividades industriales cercanas a los recursos hídricos. Al controlar las fuentes de contaminación, se puede prevenir la degradación de la calidad del agua y proteger los ecosistemas acuáticos.",
      icon: "",
    },
    {
      title: "Educación y concienciación",
      content:
        "Se llevan a cabo campañas de educación y concienciación para informar a los agricultores, residentes y usuarios de la Huerta de Valencia sobre la importancia de preservar la calidad del agua. Se promueven prácticas responsables de uso del agua, como el riego eficiente y el uso adecuado de productos químicos. Además, se fomenta la participación activa de la comunidad en la protección de los recursos hídricos y la denuncia de actividades que puedan afectar negativamente la calidad del agua.",
      icon: "",
    },
    {
      title: "Colaboración con entidades reguladoras",
      content:
        "Se establecen alianzas y colaboraciones con entidades reguladoras y organismos encargados de la gestión del agua para garantizar el cumplimiento de los estándares de calidad del agua establecidos. Esto incluye la coordinación de esfuerzos en el monitoreo, la recopilación de datos y la implementación de medidas de protección y conservación de la calidad del agua.",
      icon: "",
    },
  ];
  
  const accordionPatrimony = [
    {
      title: "Patrimonio humano",
      content:
        "El patrimonio humano de la huerta está estrechamente ligado a la actividad agrícola y a la forma de vida de las personas que trabajan y han trabajado en la huerta a lo largo de los años. Los conocimientos tradicionales transmitidos de generación en generación, las técnicas agrícolas ancestrales y las costumbres locales conforman un patrimonio humano invaluable. Preservar este legado es esencial para mantener viva la conexión entre las comunidades locales y la tierra, así como para salvaguardar la diversidad cultural y promover el respeto por el trabajo de los agricultores.",
      icon: "",
    },
    {
      title: "Patrimonio paisajístico",
      content:
        "El paisaje de la huerta es único y reconocible por sus características distintivas, como los campos de cultivo, los canales de riego, los caminos rurales y las masías tradicionales. Este paisaje ha sido modelado por siglos de agricultura sostenible y se ha convertido en un símbolo de identidad para la región. El patrimonio paisajístico de la huerta es apreciado tanto por su belleza estética como por su importancia ecológica, ya que alberga una gran diversidad de flora y fauna.</li>",
      icon: "",
    },
    {
      title: "Patrimonio cultural",
      content:
        "La huerta tiene una rica herencia cultural que abarca desde la gastronomía hasta las festividades y las manifestaciones artísticas. La cocina tradicional de la huerta, basada en productos frescos y locales, es reconocida por su calidad y autenticidad. Además, las festividades y celebraciones populares, como las Fallas, las fiestas de la Virgen de los Desamparados y las romerías, forman parte integral de la identidad cultural de la huerta. El patrimonio cultural de la huerta se conserva a través de la transmisión oral, las tradiciones vivas y los museos locales, contribuyendo así a la preservación de la memoria colectiva y el enriquecimiento cultural de la comunidad.",
      icon: "",
    },
  ];
  
  const accordionDigitalPatrimony = [
    {
      title: "Conservación a largo plazo",
      content:
        "La digitalización de elementos del patrimonio permite protegerlos de los daños físicos y del deterioro que pueden sufrir con el tiempo. Los documentos, fotografías, pinturas y otros objetos digitalizados pueden ser almacenados en formatos seguros y preservados de manera virtual, garantizando su supervivencia y accesibilidad.",
      icon: "",
    },
    {
      title: "Accesibilidad global",
      content:
        "La gestión digital del patrimonio elimina las barreras geográficas y temporales, ya que las colecciones digitales pueden ser accesibles en cualquier momento y desde cualquier lugar. Esto abre oportunidades para que investigadores, académicos, estudiantes y el público en general puedan explorar y estudiar el patrimonio de la Huerta de Valencia sin restricciones.",
      icon: "",
    },
    {
      title: "Difusión y divulgación",
      content:
        "La digitalización del patrimonio permite su difusión a través de plataformas en línea, redes sociales y sitios web especializados. Esto facilita la divulgación del patrimonio y promueve la apreciación y el conocimiento público de su valor histórico y cultural.",
      icon: "",
    },
    {
      title: "Investigación y análisis",
      content:
        "La gestión digital del patrimonio facilita la investigación y el análisis en profundidad de los elementos digitales. Los investigadores y académicos pueden examinar, comparar y estudiar los documentos y objetos digitales para obtener nuevas perspectivas y descubrimientos sobre la historia, la cultura y las tradiciones de la Huerta de Valencia.",
      icon: "",
    },
    {
      title: "Conservación de la memoria colectiva",
      content:
        "La gestión digital del patrimonio contribuye a la preservación de la memoria colectiva al capturar y documentar elementos significativos de la historia y la cultura de la Huerta de Valencia. Esto asegura que las generaciones futuras tengan acceso a su legado cultural y puedan apreciar y aprender de él.",
      icon: "",
    },
  ];
  
  const accordionCircularEconomy = [
    {
      title: "Digitalización del patrimonio hidráulico",
      content:
        "La digitalización de elementos del patrimonio hidráulico, como canales, acequias, norias y otros sistemas de irrigación tradicionales, permite crear una representación digital precisa de estos elementos. Esta digitalización ayuda a preservar y difundir el conocimiento sobre estas estructuras históricas y facilita su gestión y mantenimiento.",
      icon: "",
    },
    {
      title: "Creación de valor digital",
      content:
        "La digitalización del patrimonio hidráulico genera un valor digital que puede ser utilizado en diferentes formas. Esta representación digital puede ser utilizada para la planificación y gestión de los recursos hídricos, la promoción del turismo cultural y la educación sobre la importancia del patrimonio hidráulico en la Huerta de Valencia.",
      icon: "",
    },
    {
      title: "Promoción del turismo cultural",
      content:
        "La economía circular digital basada en el patrimonio hidráulico impulsa el desarrollo del turismo cultural en la Huerta de Valencia. La creación de experiencias turísticas basadas en el patrimonio hidráulico digitalizado atrae a visitantes interesados en explorar la riqueza histórica y cultural de la región, generando beneficios económicos y fomentando el respeto y la preservación del patrimonio.",
      icon: "",
    },
    {
      title: "Promoción del turismo cultural",
      content:
        "La economía circular digital basada en el patrimonio hidráulico impulsa el desarrollo del turismo cultural en la Huerta de Valencia. La creación de experiencias turísticas basadas en el patrimonio hidráulico digitalizado atrae a visitantes interesados en explorar la riqueza histórica y cultural de la región, generando beneficios económicos y fomentando el respeto y la preservación del patrimonio.",
      icon: "",
    },
    {
      title: "Participación y colaboración ciudadana",
      content:
        "La economía circular digital basada en el patrimonio hidráulico promueve la participación ciudadana y la colaboración en la gestión del agua. La creación de plataformas digitales y herramientas interactivas permite a los ciudadanos contribuir con información, compartir conocimientos y participar en iniciativas de conservación y uso sostenible del agua.",
      icon: "",
    },
  ];
  
  const accordionInitiatives = [
    {
      title: "Rutas turísticas",
      content:
        "Se están desarrollando rutas turísticas temáticas que permiten a los visitantes explorar y descubrir los elementos del patrimonio hidráulico de la Huerta de Valencia. Estas rutas ofrecen la oportunidad de conocer los sistemas de riego tradicionales, los acueductos históricos, los molinos de agua y otros elementos destacados del patrimonio.",
      icon: "",
    },
    {
      title: "Interpretación del patrimonio",
      content:
        "Se están creando centros de interpretación y espacios de divulgación que brindan información detallada sobre la historia, el valor y la importancia del patrimonio hidráulico de la Huerta de Valencia. Estos espacios permiten a los visitantes sumergirse en el contexto histórico y comprender mejor la relevancia cultural y medioambiental de los sistemas hidráulicos.",
      icon: "",
    },
    {
      title: "Actividades educativas y culturales",
      content:
        "Se llevan a cabo actividades educativas y culturales dirigidas a diferentes públicos, como talleres, charlas, exposiciones y eventos temáticos. Estas actividades tienen como objetivo difundir el conocimiento y fomentar la participación activa de la comunidad en la preservación y valorización del patrimonio histórico.",
      icon: "",
    },
    {
      title: "Digitalización y accesibilidad",
      content:
        "Se está trabajando en la digitalización y accesibilidad del patrimonio histórico, utilizando tecnologías como aplicaciones móviles, realidad aumentada y plataformas digitales. Esto permite que un mayor número de personas puedan acceder a la información y disfrutar de experiencias interactivas que resalten el valor del patrimonio hidráulico.",
      icon: "",
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <section className="h-fit  md:w-screen overflow-x-clip flex  flex-col md:flex-row relative  py-20">
        <div className="w-full justify-start z-10 md:justify-center  flex h-full gap-y-5 md:gap-y-10 flex-col p-4 ">
          <div className="flex-col-reverse w-full justify-start items-start gap-y-2 flex">
            {/* <h1 className="max-w-[22ch]">
              Optimización y Sostenibilidad del Agua en la {""}
              <span className="inline-flex relative z-0">
                Huerta de Valencia
                <div className="absolute -z-10 w-full h-3 bottom-2 bg-blue-300" />
              </span>
            </h1> */}
            <h1 className=" text-blue-600 te xt-xl font-bold lg:te xt-2xl uppercase leading-7">
              Gestion del Agua
            </h1>
          </div>
        </div>
        {/* <MainIcon className="w-full absolute h-fit bottom-40 -right-2 md:bottom-1/2 md:translate-y-1/2 md:right-0  aspect-square ml-auto max-w-40 md:max-w-96 " />
        <span className="w-full h-auto right-0  flex justify-center bottom-0 absolute">
          <svg
            className="w-full max-h-32 sm:max-h-48 md:max-h-full transition-[max-height] min-w-max h-full"
            width="1440"
            height="198"
            viewBox="0 0 1440 198"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 49.8667L26.72 58.96C53.28 67.9067 106.72 86.0933 160 73.3333C213.28 60.5733 266.72 17.16 320 15.1066C373.28 13.2 426.72 52.8 480 64.8267C533.28 76.7067 586.72 61.16 640 55.2934C693.28 49.4267 746.72 53.24 800 54.7066C853.28 56.1733 906.72 55.2933 960 50.3066C1013.28 45.4666 1066.72 36.6666 1120 39.3066C1173.28 42.0933 1226.72 56.1734 1280 51.6267C1333.28 46.9334 1386.72 23.4667 1413.28 11.7333L1440 0V198H1413.28C1386.72 198 1333.28 198 1280 198C1226.72 198 1173.28 198 1120 198C1066.72 198 1013.28 198 960 198C906.72 198 853.28 198 800 198C746.72 198 693.28 198 640 198C586.72 198 533.28 198 480 198C426.72 198 373.28 198 320 198C266.72 198 213.28 198 160 198C106.72 198 53.28 198 26.72 198H0V49.8667Z"
              className="fill-blue-500 "
            />
            <path
              className="fill-blue-300 dark:fill-blue-700"
              d="M0 55.7334L26.72 57.2C53.28 58.6667 106.72 61.6 160 58.3733C213.28 55.2933 266.72 45.9067 320 54.56C373.28 63.0667 426.72 89.4667 480 97.24C533.28 105.16 586.72 94.3067 640 74.5067C693.28 54.7067 746.72 25.96 800 17.3067C853.28 8.80001 906.72 20.5334 960 41.0667C1013.28 61.6 1066.72 90.9333 1120 103.84C1173.28 116.893 1226.72 113.373 1280 112.64C1333.28 111.907 1386.72 113.96 1413.28 114.84L1440 115.867V198H1413.28C1386.72 198 1333.28 198 1280 198C1226.72 198 1173.28 198 1120 198C1066.72 198 1013.28 198 960 198C906.72 198 853.28 198 800 198C746.72 198 693.28 198 640 198C586.72 198 533.28 198 480 198C426.72 198 373.28 198 320 198C266.72 198 213.28 198 160 198C106.72 198 53.28 198 26.72 198H0V55.7334Z"
            />
            <path
              d="M0 115.867L26.72 113.667C53.28 111.467 106.72 107.067 160 105.16C213.28 103.107 266.72 103.693 320 111.027C373.28 118.36 426.72 132.44 480 135.667C533.28 138.893 586.72 130.973 640 123.933C693.28 116.893 746.72 110.44 800 114.106C853.28 117.773 906.72 131.56 960 138.893C1013.28 146.227 1066.72 147.107 1120 135.227C1173.28 123.2 1226.72 98.2665 1280 95.6265C1333.28 92.8399 1386.72 112.493 1413.28 122.173L1440 132V198H1413.28C1386.72 198 1333.28 198 1280 198C1226.72 198 1173.28 198 1120 198C1066.72 198 1013.28 198 960 198C906.72 198 853.28 198 800 198C746.72 198 693.28 198 640 198C586.72 198 533.28 198 480 198C426.72 198 373.28 198 320 198C266.72 198 213.28 198 160 198C106.72 198 53.28 198 26.72 198H0V115.867Z"
              className="fill-blue-100 dark:fill-blue-900"
            />
          </svg>
        </span> */}
      </section>

      <section className=" bg-blue-100 dark:bg-blue-900  -translate-y-px">
        <div className="">
        <ImageFrame className=" w-full object-bottom" src={FrameImg} alt="Frame" />

          <h2 className="text-center">
          Importancia de la gestión del agua en la Huerta de Valencia          </h2>
          <p className="text-left ">
          La gestión del agua desempeña un papel crucial en la Huerta de Valencia, una región conocida por su rica tradición agrícola y su valioso patrimonio cultural. A continuación, se detallan algunas razones por las cuales la gestión del agua es de vital importancia en esta área:
          </p>
          <AnimatedAccordionItem color="blue" items={accordionImportance} />

          <p className="text-left">
          La gestión del agua en la Huerta de Valencia es esencial para garantizar la sostenibilidad agrícola, proteger el valioso patrimonio cultural y natural, adaptarse al cambio climático y promover el uso responsable de los recursos hídricos. Es fundamental implementar soluciones innovadoras y estrategias eficientes para una gestión efectiva del agua y asegurar un futuro sostenible para esta región única.
          </p>
        </div>
      </section>
      <section className=" -translate-y-px">
        <div className="">
          <h2 className="text-center">
            Soluciones innovadoras para una gestión eficiente del agua
          </h2>
          <p className="text-left ">
          La gestión eficiente del agua es fundamental para asegurar un uso responsable y sostenible de este recurso vital en la Huerta de Valencia. A continuación, se presentan algunas soluciones innovadoras que se están implementando para lograr una gestión eficiente del agua en la región:
          </p>
          <AnimatedAccordionItem color="blue" items={accordionSolutions} />

          <p className="text-left">
          Estas soluciones innovadoras, combinadas con la concienciación y la participación activa de agricultores y comunidades locales, contribuyen a una gestión más eficiente y sostenible del agua en la Huerta de Valencia. Al adoptar tecnologías avanzadas y prácticas agrícolas responsables, podemos garantizar la disponibilidad de agua a largo plazo y preservar los recursos naturales y culturales de esta región tan especial.
          </p>
        </div>
      </section>

      <section className=" -translate-y-px">
        <div className="">
          <h2 className="text-center">
          Sistemas de control y monitoreo en tiempo real de la Huerta de Valencia
          </h2>
          <p className="text-left ">
          En la Huerta de Valencia, vamos a  implementar sistemas de control y monitoreo en tiempo real para optimizar la gestión del agua y garantizar un uso eficiente de este recurso vital. Estos sistemas proporcionaran información en tiempo real sobre el estado de los recursos hídricos y permiten tomar decisiones basadas en datos precisos. A continuación, se describen algunas de las características y beneficios de estos sistemas:
          </p>
          <AnimatedAccordionItem color="blue" items={accordionSistemas} />

          <p className="text-left">
          La implementación de sistemas de control y monitoreo en tiempo real en la Huerta de Valencia mejora significativamente la gestión del agua al proporcionar información precisa y actualizada sobre los recursos hídricos. Esto permite una toma de decisiones más efectiva, un uso eficiente del agua y una respuesta rápida ante situaciones anómalas. En última instancia, estos sistemas contribuyen a garantizar la sostenibilidad de la Huerta de Valencia y la conservación de sus recursos naturales y culturales.
          </p>
        </div>
      </section>

      <section className=" -translate-y-px">
        <div className="">
          <h2 className="text-center">
          Monitoreo y preservación de la calidad del agua
          </h2>
          <p className="text-left ">
          En la Huerta de Valencia, el monitoreo y la preservación de la calidad del agua son aspectos fundamentales para garantizar la sostenibilidad y la salud de los recursos hídricos. A continuación, se presentan algunas estrategias y medidas utilizadas para llevar a cabo el monitoreo y la preservación de la calidad del agua en la Huerta de Valencia:          </p>
          <AnimatedAccordionItem color="blue" items={accordionMonitor} />

          <p className="text-left">
          El monitoreo y la preservación de la calidad del agua en la Huerta de Valencia son fundamentales para proteger los ecosistemas acuáticos, asegurar la salud de los cultivos y promover la sostenibilidad a largo plazo. Mediante el uso de tecnologías avanzadas, el control de fuentes de contaminación y la colaboración con la comunidad y las entidades reguladoras, se trabaja en conjunto para preservar la calidad del agua y garantizar su disponibilidad para las generaciones futuras.
          </p>
        </div>
      </section>

      <section className=" -translate-y-px">
        <div className="">
          <h2 className="text-center">
          Preservación del Patrimonio
          </h2>
          <p className="text-left ">
          Valor y significado del patrimonio humano, paisajístico y cultural de la huerta
          La Huerta de Valencia posee un valioso patrimonio humano, paisajístico y cultural que refleja siglos de historia y tradiciones arraigadas en la región. Este patrimonio es una parte integral de la identidad de la huerta y desempeña un papel fundamental en la preservación de la herencia cultural y en la promoción del turismo cultural. A continuación, exploraremos el valor y el significado de cada aspecto del patrimonio de la huerta:          
          </p>
          <AnimatedAccordionItem color="blue" items={accordionPatrimony} />

          <p className="text-left">
          El monitoreo y la preservación de la calidad del agua en la Huerta de Valencia son fundamentales para proteger los ecosistemas acuáticos, asegurar la salud de los cultivos y promover la sostenibilidad a largo plazo. Mediante el uso de tecnologías avanzadas, el control de fuentes de contaminación y la colaboración con la comunidad y las entidades reguladoras, se trabaja en conjunto para preservar la calidad del agua y garantizar su disponibilidad para las generaciones futuras.
          </p>
        </div>
      </section>
      
      <section className=" -translate-y-px">
        <div className="">
          <h2 className="text-center">
          Gestión y preservación digital del patrimonio
          </h2>
          <p className="text-left ">
          La gestión y preservación digital del patrimonio es una  herramienta innovadora y poderosa que permite conservar, difundir y compartir el valor cultural, histórico y artístico de la Huerta de Valencia. A través de la digitalización de documentos, imágenes, objetos y otros elementos del patrimonio, se crea un archivo digital que permite su preservación a largo plazo y su accesibilidad para las generaciones presentes y futuras.
          </p>
          <p className="text-left ">
              La gestión digital del patrimonio ofrece numerosos beneficios y oportunidades:
          </p>
          <AnimatedAccordionItem color="blue" items={accordionDigitalPatrimony} />

          <p className="text-left">
          Cidaqua se compromete a desarrollar herramientas y soluciones tecnológicas avanzadas para la gestión y preservación digital del patrimonio de la Huerta de Valencia. A través de la digitalización de documentos históricos, imágenes y otros elementos culturales, se busca promover la conservación y la difusión de este valioso legado, enriqueciendo así la experiencia de la comunidad local y los visitantes interesados en explorar la historia y la cultura de la huerta.
          </p>
        </div>
      </section>
      
      <section className=" -translate-y-px">
        <div className="">
          <h2 className="text-center">
          Economía circular basada en el patrimonio hidráulico
          </h2>
          <p>
              La economía circular digital basada en el patrimonio hidráulico es una estrategia innovadora que busca aprovechar el valor digital del patrimonio y promover prácticas sostenibles en la gestión del agua en la Huerta de Valencia. Esta enfoque se fundamenta en los principios de la economía circular, que busca minimizar los residuos y maximizar la eficiencia de los recursos, aplicados específicamente al contexto del patrimonio hidráulico.
          </p>
          <p>
              Algunas de las características y beneficios de la economía circular digital basada en el patrimonio hidráulico son:
          </p>
          <AnimatedAccordionItem color="blue" items={accordionCircularEconomy} />

          <p>
              Cidaqua, en colaboración con empresas y entidades destacadas, trabaja en el desarrollo de soluciones tecnológicas que impulsen la economía circular digital basada en el patrimonio hidráulico en la Huerta de Valencia. Nuestro objetivo es utilizar el potencial digital del patrimonio para promover la sostenibilidad, la preservación del agua y la valoración de la cultura e historia de la región.
          </p>
        </div>
      </section>
      
      <section className=" -translate-y-px">
        <div className="">
          <h2 className="text-center">
          Iniciativas de turismo cultural y valorizacion del patrimonio histórico
          </h2>
          <p>
                    En el marco de Cidaqua, se están impulsando diversas iniciativas de turismo cultural y valorización del patrimonio histórico de la Huerta de Valencia. Estas iniciativas buscan promover el conocimiento y la apreciación de la riqueza cultural e histórica asociada al patrimonio hidráulico de la región. Algunas de estas acciones incluyen:
                </p>
          <AnimatedAccordionItem color="blue" items={accordionInitiatives} />

          <p>
                        Estas iniciativas de turismo cultural y valorización del patrimonio histórico no solo contribuyen a la promoción del patrimonio de la Huerta de Valencia, sino que también generan beneficios económicos y sociales para la región al impulsar el turismo sostenible, el desarrollo local y la conciencia sobre la importancia de conservar y proteger el legado histórico de la zona.
                </p>
        </div>
      </section>

      {/* <section>
        <Contacts />
      </section> */}
    </div>
  );
};

export default WaterManagement;
