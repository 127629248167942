import FibsenLogo from "assets/img/partners/fibsen_logo.svg";
import BaukunstLogo from "assets/img/partners/baukunst_logo.svg";

import Javier from "assets/img/people/javier.jpeg";
import Beatriz from "assets/img/people/beatriz.jpeg";
import Adolfo from "assets/img/people/adolfo.webp";
import Sebastian from "assets/img/people/sebastian.jpeg";
import DavidS from "assets/img/people/david-s.png";
import DavidC from "assets/img/people/david-c.jpeg";
import Luis from "assets/img/people/luis-romero-cortes.jpeg";
import { Button } from "components/ui/button";

const OurTeam = () => {
  const ourTeam = [
    {
      name: "Adolfo Ibañez Vila",
      about:
        "CEO, Arquitecto Superior con especialización en Restauración de Monumentos",
      image: Adolfo,
      linkedIn: "https://www.linkedin.com/in/adolfo-ibañez-vila-2b792316/",
      logo: BaukunstLogo,
    },
    {
      name: "David Cervera",
      about: "CTO, Desarollador Full-Stack",
      image: DavidC,
      linkedIn: "https://www.linkedin.com/in/david-cervera-7156ba17/",
      logo: BaukunstLogo,
    },
    {
      name: "Sebastian Jans",
      about: "CDO, Diseñador e Desarollador Front-End",
      image: Sebastian,
      linkedIn: "https://www.linkedin.com/in/sebajans/",
      logo: BaukunstLogo,
    },
    {
      name: "Luis Romero Cortes",
      about: "CTO & Co-Founder at Fibsen",
      image: Luis,
      linkedIn: "https://www.linkedin.com/in/luis-romero-cortes/",
      logo: FibsenLogo,

    },
    {
      name: "Javier Sanz",
      about: "Ingeniero especializado en Detección de Fibra Óptica",
      image: Javier,
      linkedIn: "https://www.linkedin.com/in/javiersanzlatorre/",
      logo: FibsenLogo,
    },
    {
      name: "David Salces",
      about: "Co-founder, COO and FPGA developer at Fibsen",
      image: DavidS,
      linkedIn: "https://www.linkedin.com/in/david-salces-9b66b121/",
      logo: FibsenLogo,
    },
    // {
    //   name: "Beatriz Segura Garcia",
    //   about: "Co-Founder & Business Development Director",
    //   image: Beatriz,
    //   logo: LogoWeTheRoot,
    // },
    
    // {
    //   name: "Lidia Garcia",
    //   about: "Ingeniera agrónoma especializada en políticas públicas",
    //   image: Lidia,
    //   linkedIn: "https://www.linkedin.com/in/lidia-garcía-garcía-450999b0/",
    //   logo: LogoLasNaves,
    // },

    
  ];

  return (
    <div className="lg:grid grid-cols-4 gap-8">
      {ourTeam.map((member) => {
        return (
          <div className=" rounded-r-full py-2 gap-2 relative  flex flex-col">
            <div className="relative">
              <img
                src={member.image}
                alt={member.name}
                className="bg-gray-200   rounded-lg rounded-br-3xl object-cover overflow-clip w-32 aspect-[9/12] lg:w-full"
              />
              {member.logo &&
                <img
                className="bg-white absolute -top-2 shadow-black/30 shadow-lg -right-2 px-2 py-1.5 object-contain max-h-10 rounded-md w-auto "
                src={member.logo}></img>
                
              }
                </div>
            <div className="flex flex-col gap-y-1 items-start">
              <span className="text-base font-bold">{member.name}</span>
              <span className="text-gray-800 dark:text-gray-500 text-sm">{member.about}</span>
              <Button asChild
              variant="link"
              className="hover:text-blue-500 px-0 py-0 dark:hover:text-blue-400"
              >
                <a href={member.linkedIn} target="_blank" rel="noreferrer">
                  LinkedIn
                </a>
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OurTeam;
