import { ReactComponent as MainIcon } from "assets/page-header-items/social-impact-item.svg";

// import AnimatedAccordionItem from 'components/molecules/AnimatedAccordionItem';
import AnimatedAccordionItem from "components/molecules/AnimatedAccordionItem";
import Contacts from "components/sections/Contacts";
import {ImageFrame} from "components/ui/ImageFrame";
import FrameImg from "assets/img/social-impact/hero.webp";

const SocialImpact: React.FC = () => {
  const accordionCampañas = [
    {
      title: "Educación sobre la importancia del agua",
      content:
        "Se desarrollan programas educativos dirigidos a diferentes grupos de edad, desde niños hasta adultos, con el fin de transmitir conocimientos sobre la importancia del agua para la vida, la agricultura y el medio ambiente. Estas actividades educativas buscan crear conciencia sobre la necesidad de conservar y utilizar el agua de manera eficiente.",
      icon: "",
    },
    {
      title: "Promoción de prácticas sostenibles",
      content:
        "Se fomenta la adopción de prácticas sostenibles en el uso del agua, tanto en el ámbito doméstico como en el sector agrícola. Se brindan consejos y recomendaciones para reducir el consumo de agua, utilizar sistemas de riego eficientes y aprovechar al máximo este recurso limitado. Además, se promueve el uso de tecnologías y soluciones innovadoras que contribuyan a una gestión más eficiente del agua.",
      icon: "",
    },
    {
      title: "Participación comunitaria",
      content:
        "Se busca involucrar a la comunidad en la toma de decisiones y acciones relacionadas con la gestión del agua. Se realizan actividades de participación ciudadana, como talleres, foros y reuniones, donde se fomenta el diálogo y se recogen ideas y opiniones de los ciudadanos. Esto crea un sentido de pertenencia y corresponsabilidad en la gestión del agua.",
      icon: "",
    },
    {
      title: "Alianzas con organizaciones y entidades locales",
      content:
        "Se establecen alianzas estratégicas con organizaciones y entidades locales, como escuelas, asociaciones de vecinos y empresas, para fortalecer el impacto de las campañas de concienciación. Estas colaboraciones permiten llegar a un público más amplio y generar un mayor impacto en la comunidad.",
      icon: "",
    },

  ];
  const accordionPromotion = [
    {
      title: "Divulgación de la historia y el significado",
      content:
        "A través de publicaciones, exposiciones, y material educativo, se difunde información sobre la historia y el papel crucial que desempeñan las infraestructuras hidráulicas en la Huerta de Valencia. Se resaltan los avances tecnológicos, las técnicas tradicionales y la importancia histórica de estos sistemas de riego y canales.",
      icon: "",
    },
    {
      title: "Valorización del patrimonio",
      content:
        "Se promueve el respeto y la conservación de las infraestructuras hidráulicas como un patrimonio colectivo. Se destaca su valor arquitectónico, ingenieril y cultural, así como su contribución al desarrollo económico y social de la región. Se busca generar un sentido de pertenencia y orgullo entre los habitantes de la Huerta de Valencia.",
      icon: "",
    },
    {
      title: "Visitas guiadas y actividades educativas",
      content:
        "Se organizan visitas guiadas a las infraestructuras hidráulicas, permitiendo a los visitantes conocer de cerca su funcionamiento y su importancia histórica. Además, se llevan a cabo actividades educativas, como talleres y charlas, para promover una comprensión más profunda del patrimonio hidráulico y su relevancia en el pasado y presente de la región.",
      icon: "",
    },
    {
      title: "Cooperación con instituciones culturales",
      content:
        "Cidaqua establece colaboraciones con instituciones culturales, como museos, centros de interpretación y asociaciones históricas, para desarrollar proyectos conjuntos de investigación, exhibiciones y actividades relacionadas con el patrimonio hidráulico. Estas alianzas fortalecen el alcance y el impacto de las iniciativas de valorización del patrimonio.",
      icon: "",
    },
  ];
  
  const accordionAccessibility = [
    {
      title: "Diseño inclusivo",
      content:
        "Nos aseguramos de que nuestros espacios, materiales y recursos estén diseñados de manera que sean accesibles para todas las personas. Consideramos aspectos como rampas de acceso, señalización clara y textos legibles, con el objetivo de eliminar barreras físicas y comunicativas.",
      icon: "",
    },
    {
      title: "Tecnologías accesibles",
      content:
        "Utilizamos tecnologías y herramientas que facilitan la accesibilidad, como software de lectura de pantalla y ajustes de contraste y tamaño de fuente. Además, nos esforzamos por cumplir con los estándares de accesibilidad web en nuestra página y plataformas digitales.",
      icon: "",
    },
    {
      title: "Sensibilización y formación",
      content:
        "Capacitamos a nuestro personal y colaboradores para que comprendan la importancia de la accesibilidad y sepan cómo brindar una experiencia inclusiva. Promovemos la sensibilización y el respeto hacia las diferentes capacidades, creando un entorno acogedor y libre de barreras.",
      icon: "",
    },
    {
      title: "Adaptación de actividades",
      content:
        "Adaptamos nuestras actividades y eventos para garantizar la participación de todas las personas. Esto puede incluir la disponibilidad de intérpretes de lenguaje de señas, facilitadores para personas con discapacidades cognitivas y la adaptación de materiales para diferentes habilidades.",
      icon: "",
    },
    {
      title: "Colaboración con organizaciones especializadas",
      content:
        "Trabajamos en colaboración con organizaciones y grupos especializados en accesibilidad para recibir orientación y asesoramiento en nuestras iniciativas. Buscamos aprender y mejorar constantemente nuestras prácticas de accesibilidad.",
      icon: "",
    },
  ];

  const accordionSocialBenefits = [
    {
      title: "Generación de empleo",
      content:
        "Cidaqua impulsa la creación de empleo local al promover actividades relacionadas con la gestión del agua, la preservación del patrimonio y el turismo cultural. Esto contribuye al desarrollo económico de la región y brinda oportunidades de empleo sostenibles.",
      icon: "",
    },
    {
      title: "Desarrollo económico local",
      content:
        "Al mejorar la eficiencia en el uso del agua y promover prácticas agrícolas sostenibles, Cidaqua ayuda a optimizar la producción agrícola en la Huerta de Valencia. Esto conduce a un aumento en la productividad y la rentabilidad de las explotaciones agrícolas, lo que a su vez fortalece la economía local.",
      icon: "",
    },
    {
      title: "Ahorro de recursos",
      content:
        "La gestión eficiente del agua y la implementación de tecnologías avanzadas en Cidaqua permiten un uso más racional de los recursos hídricos. Esto no solo contribuye a la preservación de un recurso vital, sino que también reduce los costos asociados al consumo excesivo de agua y energía.",
      icon: "",
    },
    {
      title: "Mejora de la calidad de vida",
      content:
        "Al promover la conservación del patrimonio natural y cultural de la Huerta de Valencia, Cidaqua contribuye a mejorar la calidad de vida de las comunidades locales. El acceso a espacios naturales y la valorización de la herencia cultural generan un mayor bienestar y un sentido de identidad y pertenencia en la población.",
      icon: "",
    },
    {
      title: "Turismo sostenible",
      content:
        "Cidaqua impulsa iniciativas de turismo cultural basadas en el patrimonio histórico y paisajístico de la Huerta de Valencia. Esto atrae a visitantes interesados en experiencias auténticas y sostenibles, lo que genera ingresos y contribuye al desarrollo de actividades económicas complementarias, como la hostelería y los servicios turísticos",
      icon: "",
    },
    {
      title: "Concienciación y participación comunitaria",
      content:
        "El proyecto Cidaqua promueve la concienciación sobre la importancia del agua, la preservación del patrimonio y la necesidad de una gestión sostenible. A través de programas educativos y actividades de participación comunitaria, se fomenta el compromiso ciudadano y se fortalecen los lazos sociales en la región.",
      icon: "",
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <section className="h-fit w-screen  overflow-x-clip flex  flex-col md:flex-row relative  py-20">
        <div className="w-full justify-start z-10 md:justify-center  flex h-full gap-y-10 flex-col p-4 ">
          <div className="flex-col-reverse justify-start items-start gap-y-2 flex">
            
            <h1 className=" text-red-600  font-bold uppercase leading-7">
              Impacto Social
            </h1>
          </div>
        </div>

      </section>

      <section className=" bg-red-100 dark:bg-red-900  -translate-y-px">
        <div className="">
        <ImageFrame className=" w-full object-bottom" src={FrameImg} alt="Frame" />

          <h2 className="text-center">
            Campañas de concienciación sobre el valor del agua
          </h2>
          <p className="text-left ">
            Como parte del compromiso de Cidaqua con el impacto social, se
            llevan a cabo campañas de concienciación sobre el valor del agua en
            la Huerta de Valencia. Estas campañas tienen como objetivo
            sensibilizar a la sociedad sobre la importancia de este recurso
            vital y promover prácticas responsables en su uso. Algunos aspectos
            clave de estas campañas incluyen:
          </p>
          <AnimatedAccordionItem color="red" items={accordionCampañas} />

          <p className="text-left">
            El objetivo principal de estas campañas de concienciación es
            fomentar una cultura del agua responsable, donde se valore y se
            utilice de manera consciente y sostenible. A través de la educación
            y la participación ciudadana, Cidaqua busca generar un cambio de
            actitud hacia el uso del agua y promover una gestión responsable que
            garantice su disponibilidad a largo plazo.
          </p>
        </div>
      </section>
      <section className=" -translate-y-px">
        <div className="">
          <h2 className="text-center">
          Promoción de la importancia histórica y patrimonial de las infraestructuras hidráulicas
          </h2>
          <p className="text-left ">
          La promoción de la importancia histórica y patrimonial de las infraestructuras hidráulicas es un aspecto fundamental en las actividades de Cidaqua. Se busca destacar el valor cultural y patrimonial de estas estructuras, que son parte integral de la historia y la identidad de la Huerta de Valencia. Algunos enfoques clave en la promoción de este patrimonio incluyen:

          </p>
          <AnimatedAccordionItem color="red" items={accordionPromotion} />

          <p className="text-left">
          La promoción de la importancia histórica y patrimonial de las infraestructuras hidráulicas busca generar conciencia y aprecio hacia estos elementos clave de la Huerta de Valencia. Al resaltar su valor cultural, se fomenta su preservación y se promueve la identidad local, contribuyendo a la protección y el legado de este valioso patrimonio para las generaciones futuras.
          </p>
        </div>
      </section>

      <section className=" -translate-y-px">
        <div className="">
          <h2 className="text-center">
          Iniciativas de accesibilidad universal para todos los usuarios
          </h2>
          <p className="text-left ">
          En Cidaqua, implementamos iniciativas de accesibilidad universal para asegurar que todas las personas, independientemente de sus capacidades o discapacidades, puedan participar y beneficiarse de nuestras actividades. Nuestro enfoque se basa en las siguientes acciones:

          </p>
          <AnimatedAccordionItem color="red" items={accordionAccessibility} />

          <p className="text-left">
          Nuestro objetivo final es crear un entorno inclusivo donde todos los usuarios se sientan bienvenidos y tengan igualdad de oportunidades para participar en nuestras actividades. A través de nuestras iniciativas de accesibilidad, buscamos promover la igualdad y la inclusión en la sociedad.</p>
        </div>
      </section>

      <section className=" -translate-y-px">
        <div className="">
          <h2 className="text-center">
          Beneficios sociales y económicos del proyecto
          </h2>
          <p className="text-left ">
          El proyecto Cidaqua tiene múltiples beneficios sociales y económicos para la Huerta de Valencia y sus comunidades. A continuación, se presentan algunos de los principales beneficios:



          </p>
          <AnimatedAccordionItem color="red" items={accordionSocialBenefits} />

          <p className="text-left">
          En resumen, Cidaqua genera beneficios sociales y económicos al impulsar el desarrollo local, promover la sostenibilidad, preservar el patrimonio y mejorar la calidad de vida de las comunidades en la Huerta de Valencia. Este proyecto es un ejemplo de cómo la innovación y la gestión responsable pueden generar un impacto positivo en múltiples aspectos de la sociedad.
          </p>
        </div>
      </section>

      {/* <section>
        <Contacts />
      </section> */}
    </div>
  );
};

export default SocialImpact;
