const PrivacyPolicy = () => {
  return (
    <section>
      <div>
        <h1>Política de Cookies</h1>

<div className="flex flex-col gap-y-2">

        <p>
          Esta Política de Cookies es complemento de la Política de Privacidad
          del sitio web www.cidaqua.com (en adelante, el “Sitio Web”) (en
          adelante, “BAUKUNST ARQUITECTURA Y PATRIMONIO VIRTUAL SL.,”). Sitio
          web:
        </p>
        <p>
          Responsable Explotación de los datos: BAUKUNST ARQUITECTURA Y
          PATRIMONIO VIRTUAL SL., representada por D. Adolfo Bartolomé Ibáñez
          Vila, con DNI no 44867848T. con domicilio sito en Calle General
          Sanmartín 7, puerta 11 46004 de Valencia y dirección de correo
          electrónico contacto@baukunst.es
        </p>
        <p>
          Responsable de almacenamiento de los datos: BAUKUNST ARQUITECTURA Y
          PATRIMONIO VIRTUAL SL., con domicilio en Calle General Sanmartín 7,
          puerta 11 con CIF no B09737362, y dirección de correo electrónico
          Hola@cidaqua.com. De conformidad con lo dispuesto en la Reglamento
          General UE 679/2016 de Protección de Datos (en adelante, “RGPD”) y en
          la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos y
          Garantía de los Derechos Digitales (en adelante, “LOPDGDD”) mediante
          la aceptación de la presente Política de Cookies el usuario web (en
          adelante, el “Usuario”) PRESTA SU CONSENTIMIENTO informado, expreso,
          libre e inequívoco para tratar los datos personales recabados a través
          del Sitio Web a través de la información recabada por las cookies.
        </p>
        <p>
          Con el fin de facilitar la navegación por el Sitio Web, BAUKUNST
          ARQUITECTURA Y PATRIMONIO VIRTUAL SL, le comunicamos que utiliza
          cookies u otros métodos de funcionalidad similar (en adelante, las
          “Cookies”) y por ello le informamos de los siguiente: 
        </p>
</div>
        <div className="flex flex-col gap-y-2">
        <h3>

          1. ¿Qué es una
          cookie? 
        </h3>

        <p>
          Se presta el consentimiento expreso para el tratamiento de
          datos personales durante el acceso al Sitio Web en aquellos
          dispositivos utilizados para realizar dicho acceso. Si se desactivan
          las Cookies, puede que el uso del Sitio Web no sea óptimo y algunas de
          las utilidades de las que dispone el Sitio Web no funcionen
          correctamente.
        </p>
        <p>
          Las Cookies son esenciales para el funcionamiento de internet, ya que
          ayudan, entre otras funciones, a identificar y resolver posibles
          errores de funcionamiento del Sitio Web.
        </p>
        </div>

        <div className="flex flex-col gap-y-2">
          <h3>

          2. Uso de Cookies por parte de BAUKUNST ARQUITECTURA Y PATRIMONIO
          VIRTUAL SL.
        </h3>
          
        <p>

          Se presta el consentimiento expreso para el tratamiento de
          datos personales durante el acceso al Sitio Web en aquellos
          dispositivos utilizados para realizar dicho acceso. Si se desactivan
          las Cookies, puede que el uso del Sitio Web no sea óptimo y algunas de
          las utilidades de las que dispone el Sitio Web no funcionen
          correctamente.
        </p>
        <p>
          Concretamente, BAUKUNST ARQUITECTURA Y PATRIMONIO VIRTUAL SL está
          utilizando las Cookies para la identificación de aquellos Usuarios que
          aceptan el uso de Cookies por parte del Sitio Web y permitir la
          instalación de las mismas, permitir el log-in de los Usuarios en
          aquellos casos en los que éste sea necesario para acceder a
          determinadas secciones del Sitio Web, así como recordar los datos de
          log-in de dichos Usuarios en futuras visitas y poder ofrecer así una
          experiencia de navegación más cómoda, así como evaluar el uso del
          Sitio Web y la actividad del mismo.
        </p>
        <p>
          Asimismo, las Cookies ayudan protegen a los Usuarios de posibles
          ataques que puedan recibir de terceros.
        </p>
        </div>

<div className="flex flex-col gap-y-2">

<h3>
          3. Cookies que utilizamos y con qué finalidades
</h3>


        <p>
          A continuación, se
          detallan las cookies utilizadas por este sitio web junto con la
          finalidad y duración de cada una de ellas:
        </p>
        <p>
          Cookies propias: son cookies estrictamente necesarias. Permiten la
          interacción del usuario por el sitio web utilizando todas sus
          funciones. Cookies Analíticas: son cookies utilizadas para el
          análisis, investigación o estadísticas con el fin de saber secciones
          más visitadas, puntos de conexión, etc y así poder mejorar el portal
          web a través. Cookies de complementos: son cookies utilizadas por
          complementos externos instalados en la web para su buen
          funcionamiento, como por ejemplo mostrar el cartel informativo sobre
          el uso de cookies o las cookies utilizadas por el sistema de chat de
          atención al cliente. Cookies de redes sociales: son cookies utilizadas
          para identificarte como usuario en la red social y poder interactuar a
          través de tu perfil a través de las APIs instaladas.
        </p>
</div>

<div className="flex flex-col gap-y-2">
<h3>

          4. Configuración del Usuario para evitar Cookies 
</h3>

        <p>
          En cumplimiento de la
          normativa legal vigente, ponemos a su disposición la información que
          le permita configurar su navegador/navegadores de internet para
          mantener su privacidad y seguridad en relación con las Cookies. Por
          ello, le facilitamos la información y enlaces a los sitios de soporte
          oficiales de los principales navegadores para que pueda decidir si
          desea o no aceptar el uso de Cookies
        </p>
        <p>
          Internet Explorer: Herramientas – Opciones de Internet – Privacidad –
          Configuración; Firefox: Herramientas – Opciones – Privacidad –
          Historial – Configuración personalizada; Chrome: Configuración –
          Mostrar opciones avanzadas – Privacidad – Configuración de contenido;
          Safari: Preferencias – Seguridad. En caso de que el Usuario decida
          desactivar todas las Cookies, la calidad y rapidez del servicio podría
          disminuir. Puede encontrar más información sobre Cookies en:{" "}
          <a href="http://www.allaboutcookies.org/">
            http://www.allaboutcookies.org/
          </a>
        </p>
</div>
<div className="flex flex-col gap-y-2">

        <p>
          Si tiene dudas sobre esta Política de Cookies, puede contactar con
          BAUKUNST ARQUITECTURA Y PATRIMONIO VIRTUAL SL en contacto@baukunst.es
        </p>
        <p>28 de junio de 2023.</p>
      </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
