import CardItemWithIcon from "components/molecules/CardItemWithIcon";
import { CardItemType } from "lib/types";
import Contacts from "components/sections/Contacts";
import SensorTechnology from "./SensorTechnology";
import DesktopSwitcher from "./DesktopSwitcher";
import PhoneSwitcher from "./PhoneSwitcher";
import {ImageFrame} from "components/ui/ImageFrame";
import FrameImg from "assets/img/technology/hero.webp";
import AnimatedAccordionItem from "components/molecules/AnimatedAccordionItem";

const Technology = () => {
  const accordionInformation: CardItemType[] = [
    {
      title: "Sistemas de telemetría y control en tiempo real",
      content:
        "Implementamos sistemas de telemetría y control en tiempo real para monitorear y gestionar de manera eficiente los recursos hídricos. Estos sistemas nos permiten recopilar datos precisos sobre el flujo de agua, los niveles de humedad del suelo y otros parámetros relevantes. Utilizando esta información, podemos ajustar las operaciones de riego y garantizar un uso óptimo del agua.",
    },
    {
      title: "Sensores y dispositivos inteligentes",
      content:
        "Empleamos una variedad de sensores y dispositivos inteligentes para monitorear y recopilar datos en tiempo real sobre la calidad del agua, la temperatura, la humedad y otros indicadores relevantes. Estos dispositivos nos ayudan a detectar problemas potenciales, identificar tendencias y tomar decisiones informadas para garantizar la calidad del agua y la eficiencia en su uso.",
    },
    {
      title: "Plataformas de gestión de datos",
      content:
        "Utilizamos plataformas de gestión de datos para almacenar, analizar y visualizar la información recopilada por nuestros sistemas de monitoreo. Estas plataformas nos brindan una visión completa y en tiempo real de los datos, lo que nos permite tomar decisiones basadas en evidencia y optimizar nuestras operaciones.",
    },
    {
      title: "Modelos de predicción y análisis avanzado",
      content:
        "Aplicamos modelos de predicción y análisis avanzado para prever la demanda de agua, identificar patrones de uso y optimizar la planificación del riego. Estos modelos nos ayudan a optimizar el uso del agua y a garantizar una distribución equitativa y eficiente de los recursos hídricos en la Huerta de Valencia.",
    },
    {
      title: "Tecnología de información geográfica (GIS)",
      content:
        "Utilizamos tecnología de información geográfica para mapear y visualizar la distribución de los recursos hídricos, los sistemas de riego y otros elementos relevantes en la Huerta de Valencia. Esto nos permite tener una comprensión completa de la infraestructura hidráulica y optimizar su funcionamiento.",
    },
  ];
  const accordionSensors: CardItemType[] = [
    {
      title: "Sensores de fibra óptica para la medición de temperatura",
      content:
        "Estos sensores utilizan cambios en la longitud de onda de la luz para medir la temperatura del agua. Se pueden implementar en diferentes puntos de la red de distribución de agua para monitorear y controlar la temperatura en tiempo real, lo que es especialmente importante para garantizar la seguridad y eficiencia de los sistemas de calefacción y refrigeración.",
    },
    {
      title: "Sensores de fibra óptica para la medición de turbidez",
      content:
        "La turbidez del agua es un indicador importante de su calidad y claridad. Los sensores de fibra óptica basados en la dispersión de la luz pueden medir la turbidez del agua al detectar las partículas suspendidas en ella. Estos sensores son utilizados para controlar y mantener la calidad del agua en ríos, embalses y sistemas de suministro de agua potable.",
    },
    {
      title: "Sensores de fibra óptica para la medición de pH",
      content:
        "El pH es otro parámetro crítico para determinar la calidad del agua. Los sensores de fibra óptica pueden ser diseñados para medir el pH mediante la utilización de recubrimientos sensibles a los cambios de acidez o alcalinidad. Estos sensores ofrecen una medición precisa y estable del pH del agua, lo que permite tomar medidas oportunas para mantener la calidad y evitar la corrosión en las infraestructuras hidráulicas.",
    },
    {
      title: "Sensores de fibra óptica para la detección de contaminantes",
      content:
        "Los sensores de fibra óptica pueden ser utilizados para detectar y medir diferentes contaminantes en el agua, como metales pesados, compuestos orgánicos y productos químicos tóxicos. Estos sensores se basan en la interacción entre la luz y los materiales específicos presentes en el agua, proporcionando una detección sensible y selectiva de los contaminantes.",
    },
  ];

  const accordionControlPanel: CardItemType[] = [
    {
      title: "Monitoreo de variables",
      content:
        "El panel de control recopila datos en tiempo real de diversas variables relacionadas con el ciclo del agua, como caudal, presión, nivel de agua, calidad del agua y consumo. Estos datos son presentados de forma clara y visual, lo que permite una fácil interpretación y seguimiento de los parámetros clave.",
    },
    {
      title: "Alertas y notificaciones",
      content:
        "El panel de control está diseñado para generar alertas y notificaciones automáticas en caso de que se detecten anomalías o situaciones de riesgo. Esto permite una respuesta rápida y eficiente ante cualquier evento inesperado, como fugas, bajos niveles de agua o problemas en la calidad del agua.",
    },
    {
      title: "Optimización de recursos",
      content:
        "Mediante la recopilación y análisis de datos en tiempo real, el panel de control ayuda a identificar oportunidades de ahorro y optimización en el uso de los recursos hídricos. Esto incluye la detección de fugas, la gestión eficiente de la energía, la programación inteligente de riegos y la identificación de patrones de consumo que pueden guiar la toma de decisiones para mejorar la eficiencia en el ciclo del agua.",
    },
    {
      title: "Integración de sistemas",
      content:
        "El panel de control puede integrar y sincronizar diferentes sistemas y dispositivos utilizados en la gestión del ciclo del agua, como sensores, medidores, válvulas y bombas. Esto permite una gestión centralizada y coordinada de todas las etapas del ciclo del agua, optimizando la operación y el mantenimiento de la infraestructura hídrica.",
    },
    {
      title: "Análisis y reportes",
      content:
        "El panel de control puede generar informes y análisis detallados sobre el desempeño y la eficiencia del ciclo del agua. Estos informes proporcionan datos históricos, tendencias y estadísticas que ayudan a evaluar el rendimiento y tomar decisiones estratégicas a largo plazo.",
    },
  ];
  const accordionPlatform: CardItemType[] = [
    {
      title: "Monitorización y control en tiempo real",
      content:
        "La plataforma permite la monitorización y control en tiempo real de los sistemas de riego, brindando información detallada sobre el estado del agua, el flujo de riego, la humedad del suelo y otros parámetros relevantes. Esto permite ajustar el riego de manera precisa y oportuna, evitando el uso excesivo de agua y optimizando su distribución.",
    },
    {
      title: "Programación inteligente",
      content:
        "La plataforma utiliza algoritmos y modelos predictivos para programar de manera inteligente los ciclos de riego, considerando factores como las condiciones climáticas, las necesidades hídricas de los cultivos y las características del suelo. Esto ayuda a evitar el riego innecesario o inadecuado, optimizando el consumo de agua y reduciendo los costos asociados.",
    },
    {
      title: "Sensores y tecnologías de medición",
      content:
        "La plataforma integra diversos sensores y tecnologías de medición, como sensores de humedad del suelo, sensores de temperatura, pluviómetros y estaciones meteorológicas. Estos dispositivos recopilan datos en tiempo real sobre las condiciones ambientales y la disponibilidad de agua, permitiendo un monitoreo preciso y una toma de decisiones fundamentada.",
    },
    {
      title: "Automatización y control remoto",
      content:
        "La plataforma ofrece la posibilidad de automatizar y controlar los sistemas de riego de forma remota. Esto permite realizar ajustes y cambios en la programación de riego desde cualquier ubicación, lo que facilita la gestión eficiente del agua y ahorra tiempo y recursos.",
    },
    {
      title: "Análisis de datos y reportes",
      content:
        "La plataforma recopila y analiza datos sobre el consumo de agua, los patrones de riego y otros indicadores clave. Estos datos se presentan en informes y análisis detallados, que brindan información valiosa para evaluar el rendimiento del sistema de riego, identificar áreas de mejora y tomar decisiones informadas.",
    },
  ];

  return (
    <div>
      <section className="h-fit text-white bg-gray-900 dark:bg-gray-950 overflow-x-clip flex w-full flex-col md:flex-row relative  py-20">
        <div className="w-full justify-start md:justify-center z-10 flex h-full gap-y-10 flex-col p-4 ">
          <div className="flex-col-reverse justify-start items-start gap-y-2 flex">
            <h1 className=" text-gray-100  font-bold uppercase leading-7">
              Tecnología
            </h1>
          </div>
        </div>
      </section>

      <section className="bg-gray-100 dark:bg-gray-900">
        <div className="">
          <ImageFrame className=" w-full object-bottom" src={FrameImg} alt="Frame" />
          <h2 className="text-center">
            Información detallada sobre las soluciones tecnológicas avanzadas
            utilizadas en Cidaqua
          </h2>

          <p>
            Cidaqua utiliza una variedad de soluciones tecnológicas avanzadas
            para optimizar la gestión del agua y la preservación del patrimonio
            en la Huerta de Valencia. Estas soluciones están diseñadas para
            mejorar la eficiencia, la sostenibilidad y la efectividad de
            nuestras operaciones. A continuación, se presenta una descripción de
            algunas de las principales soluciones tecnológicas utilizadas en
            Cidaqua:
          </p>
          <AnimatedAccordionItem color="gray" items={accordionInformation} />
          <p>
            Estas soluciones tecnológicas avanzadas nos permiten tomar
            decisiones más informadas, optimizar el uso del agua, mejorar la
            gestión de recursos y preservar el patrimonio de la Huerta de
            Valencia. A través de la implementación de estas tecnologías,
            buscamos lograr una gestión hídrica más eficiente, sostenible y
            resiliente en beneficio de la comunidad local y del medio ambiente.
          </p>
        </div>
      </section>
      <section className="bg-gray-100 dark:bg-gray-900">
        <div className="">
          <h2 className="text-center">
            Descripción de las tecnologías de sensores basadas en fibra óptica
          </h2>
          <p>
            Las tecnologías de sensores basadas en fibra óptica ofrecen diversas
            soluciones para la medición de variables en el agua. Estas
            tecnologías se utilizan en Cidaqua para monitorear y garantizar la
            calidad del agua en la Huerta de Valencia. Algunas de estas
            tecnologías incluyen:
          </p>
          <AnimatedAccordionItem color="gray" items={accordionSensors} />

          <p>
            Estas tecnologías de sensores basadas en fibra óptica son
            herramientas valiosas para el monitoreo y control de la calidad del
            agua en la Huerta de Valencia. Proporcionan mediciones precisas y en
            tiempo real de variables importantes, lo que facilita la toma de
            decisiones informadas y la implementación de estrategias efectivas
            para la gestión sostenible del agua.
          </p>
        </div>
      </section>
      <section className="bg-gray-100 dark:bg-gray-900">
        <div className="">
          <h2 className="text-center">
            Panel de control para la gestión digital del ciclo del agua
          </h2>
          <p>
            Un panel de control para la gestión digital del ciclo del agua es
            una herramienta centralizada que permite monitorear y controlar de
            manera eficiente los diferentes aspectos del ciclo del agua, desde
            la captación hasta el tratamiento y la distribución. Este panel de
            control utiliza tecnologías digitales y sistemas de información para
            recopilar, procesar y visualizar datos relevantes en tiempo real, lo
            que facilita la toma de decisiones informadas y la optimización de
            los recursos hídricos.
          </p>
          <p>
            Algunas características y funcionalidades clave de un panel de
            control para la gestión digital del ciclo del agua pueden incluir:
          </p>
          <AnimatedAccordionItem color="gray" items={accordionControlPanel} />
          <p>
            En resumen, un panel de control para la gestión digital del ciclo
            del agua es una herramienta esencial para optimizar la gestión de
            los recursos hídricos. Proporciona una visión integral y en tiempo
            real de todas las etapas del ciclo del agua, lo que permite una
            gestión más eficiente, sostenible y resiliente del agua en la Huerta
            de Valencia.
          </p>
        </div>
      </section>
      <section className="bg-gray-100 dark:bg-gray-900">
        <div className="">
          <h2 className="text-center">
            Plataforma para la sostenibilidad y el ahorro de agua en los
            sistemas de riego
          </h2>
          <p>
            Una plataforma para la sostenibilidad y el ahorro de agua en los
            sistemas de riego es una solución tecnológica integral diseñada para
            optimizar el uso del agua en la agricultura y promover prácticas
            sostenibles. Esta plataforma utiliza tecnologías avanzadas, análisis
            de datos y automatización para mejorar la eficiencia en los sistemas
            de riego y reducir el consumo de agua, al mismo tiempo que garantiza
            un adecuado suministro hídrico para el cultivo.
          </p>
          <p>
            Algunas características y beneficios clave de una plataforma para la
            sostenibilidad y el ahorro de agua en los sistemas de riego pueden
            incluir:
          </p>
          <AnimatedAccordionItem color="gray" items={accordionPlatform} />

          <p>
            En resumen, una plataforma para la sostenibilidad y el ahorro de
            agua en los sistemas de riego es una herramienta poderosa que
            permite optimizar el uso del agua en la agricultura, reducir el
            desperdicio y mejorar la eficiencia de los sistemas de riego. Al
            promover prácticas sostenibles y responsables, contribuye a la
            conservación de los recursos hídricos y a la producción agrícola
            sostenible en la Huerta de Valencia.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Technology;
