import { ReactComponent as MainIcon } from "assets/page-header-items/main-item-green.svg";

// import AnimatedAccordionItem from 'components/molecules/AnimatedAccordionItem';
import AnimatedAccordionItem from "components/molecules/AnimatedAccordionItem";
import Contacts from "components/sections/Contacts";
import {ImageFrame} from "components/ui/ImageFrame";
import FrameImg from "assets/img/sustainability/hero.webp";

const Sustainability: React.FC = () => {
  const accordionStrategies = [
    {
      title: "Tecnologías avanzadas de riego",
      content:
        "Cidaqua utiliza tecnologías de riego de última generación, como sistemas de riego por goteo y riego inteligente basado en la demanda de las plantas. Estas tecnologías permiten una aplicación precisa del agua, reduciendo el desperdicio y optimizando la absorción por parte de las plantas.",
    },
    {
      title: "Monitoreo en tiempo real",
      content:
        "Se implementan sistemas de monitoreo en tiempo real que brindan información precisa sobre el estado y las necesidades hídricas de los cultivos. Esto permite ajustar la cantidad de agua suministrada de manera eficiente, evitando el exceso de riego y optimizando el consumo.",
    },
    {
      title: "Reutilización y reciclaje del agua",
      content:
        "Cidaqua promueve la reutilización y el reciclaje del agua en diferentes etapas del proceso agrícola. Se implementan sistemas de captación y almacenamiento de agua de lluvia, así como sistemas de tratamiento y reciclaje de aguas residuales, reduciendo la dependencia de fuentes externas y optimizando el uso de recursos hídricos locales.",
    },
    {
      title: "Educación y capacitación",
      content:
        "Se llevan a cabo programas educativos y capacitaciones dirigidas a agricultores y comunidades locales, con el objetivo de promover prácticas de gestión del agua eficientes y sostenibles. Se proporciona información sobre técnicas de riego adecuadas, la importancia de la conservación del agua y la adopción de tecnologías y prácticas más eficientes.",
    },
    {
      title: "Mejora de la infraestructura hídrica",
      content:
        "Cidaqua trabaja en la mejora y optimización de la infraestructura hídrica existente en la Huerta de Valencia. Esto incluye la reparación y modernización de canales y sistemas de distribución de agua, reduciendo las pérdidas por filtración y asegurando un flujo más eficiente del recurso.",
    },
    {
      title: "Sensibilización y participación comunitaria",
      content:
        "Se realizan campañas de sensibilización dirigidas a agricultores, comunidades locales y usuarios del agua en general. Estas campañas tienen como objetivo crear conciencia sobre la importancia de la conservación del agua y fomentar la adopción de prácticas sostenibles en el uso del recurso.",
    },

  ];
  const accordionPromotion = [
    {
      title: "Sensores y analizadores",
      content:
        "Cidaqua utiliza una variedad de sensores y analizadores automatizados para monitorear la calidad del agua. Estos dispositivos están equipados con tecnología avanzada que permite medir parámetros como la temperatura, el pH, la conductividad eléctrica, la turbidez y la concentración de diferentes sustancias químicas y contaminantes. Estos sensores proporcionan datos precisos y confiables en tiempo real.",
    },
    {
      title: "Sistemas de telemetría",
      content:
        "Los datos recolectados por los sensores y analizadores se transmiten a través de sistemas de telemetría, lo que permite su visualización y análisis en tiempo real. Estos sistemas brindan información detallada sobre la calidad del agua, tanto en puntos específicos como en áreas más extensas de la Huerta de Valencia. Esto facilita la detección temprana de problemas y la toma de decisiones informadas.",
    },
    {
      title: "Análisis de datos",
      content:
        "Los datos recopilados a través de la tecnología de monitoreo se analizan utilizando herramientas y software especializados. Estos análisis permiten identificar patrones, tendencias y posibles riesgos para la calidad del agua. Además, los algoritmos y modelos avanzados pueden proporcionar pronósticos y alertas tempranas en caso de situaciones críticas o cambios significativos en la calidad del agua.",
    },
    {
      title: "Mantenimiento preventivo",
      content:
        "Cidaqua lleva a cabo un programa de mantenimiento preventivo de los equipos de monitoreo para garantizar su correcto funcionamiento y precisión. Se realizan calibraciones periódicas, limpieza y verificaciones para asegurar que los sensores y analizadores sigan proporcionando mediciones confiables y consistentes.",
    },
    {
      title: "Colaboración con autoridades y laboratorios",
      content:
        "Cidaqua colabora estrechamente con autoridades locales y laboratorios especializados para la validación y verificación de los datos de calidad del agua. Esto garantiza la confiabilidad y objetividad de los resultados obtenidos, y proporciona una base sólida para la toma de decisiones relacionadas con la gestión y preservación del agua en la Huerta de Valencia.",
    },
  ];
  
  const accordionPreservation = [
    {
      title: "Conservación de la biodiversidad",
      content:
        "Nos esforzamos por salvaguardar la diversidad biológica de los espacios naturales y parques asociados a la huerta. Esto implica la protección de especies vegetales y animales autóctonas, la restauración de hábitats naturales y la promoción de prácticas agrícolas sostenibles que minimicen el impacto sobre la biodiversidad.",
      icon: "",
    },
    {
      title: "Restauración de ecosistemas",
      content:
        "Implementamos programas de restauración y rehabilitación de ecosistemas en colaboración con expertos en conservación. Estos esfuerzos incluyen la revegetación de áreas degradadas, la recuperación de cauces de ríos y arroyos, y la creación de corredores ecológicos que conecten diferentes espacios naturales.",
      icon: "",
    },
    {
      title: "Gestión sostenible del agua",
      content:
        "La gestión eficiente y sostenible del agua es esencial para la preservación de los espacios naturales. Implementamos sistemas de riego inteligentes que optimizan el uso del agua y evitan el desperdicio. Además, trabajamos en la restauración y mantenimiento de humedales y zonas de filtración que contribuyen a la purificación del agua y a la conservación de los ecosistemas acuáticos.",
      icon: "",
    },
    {
      title: "Educación y sensibilización",
      content:
        "Promovemos la educación ambiental y la sensibilización sobre la importancia de la preservación de los espacios naturales y parques asociados a la huerta. Realizamos programas de divulgación y actividades educativas dirigidas a la comunidad local y a visitantes, para fomentar la valoración y el respeto por estos entornos naturales.",
      icon: "",
    },
    {
      title: "Colaboración con entidades y organizaciones",
      content:
        "Trabajamos en estrecha colaboración con entidades y organizaciones locales, regionales y nacionales dedicadas a la conservación de la naturaleza. A través de alianzas estratégicas, promovemos la coordinación de esfuerzos y el intercambio de conocimientos y mejores prácticas en materia de preservación de espacios naturales.",
      icon: "",
    },
  ];

  const accordionSocialBenefits = [
    {
      title: "Generación de empleo",
      content:
        "Cidaqua impulsa la creación de empleo local al promover actividades relacionadas con la gestión del agua, la preservación del patrimonio y el turismo cultural. Esto contribuye al desarrollo económico de la región y brinda oportunidades de empleo sostenibles.",
      icon: "",
    },
    {
      title: "Desarrollo económico local",
      content:
        "Al mejorar la eficiencia en el uso del agua y promover prácticas agrícolas sostenibles, Cidaqua ayuda a optimizar la producción agrícola en la Huerta de Valencia. Esto conduce a un aumento en la productividad y la rentabilidad de las explotaciones agrícolas, lo que a su vez fortalece la economía local.",
      icon: "",
    },
    {
      title: "Ahorro de recursos",
      content:
        "La gestión eficiente del agua y la implementación de tecnologías avanzadas en Cidaqua permiten un uso más racional de los recursos hídricos. Esto no solo contribuye a la preservación de un recurso vital, sino que también reduce los costos asociados al consumo excesivo de agua y energía.",
      icon: "",
    },
    {
      title: "Mejora de la calidad de vida",
      content:
        "Al promover la conservación del patrimonio natural y cultural de la Huerta de Valencia, Cidaqua contribuye a mejorar la calidad de vida de las comunidades locales. El acceso a espacios naturales y la valorización de la herencia cultural generan un mayor bienestar y un sentido de identidad y pertenencia en la población.",
      icon: "",
    },
    {
      title: "Turismo sostenible",
      content:
        "Cidaqua impulsa iniciativas de turismo cultural basadas en el patrimonio histórico y paisajístico de la Huerta de Valencia. Esto atrae a visitantes interesados en experiencias auténticas y sostenibles, lo que genera ingresos y contribuye al desarrollo de actividades económicas complementarias, como la hostelería y los servicios turísticos",
      icon: "",
    },
    {
      title: "Concienciación y participación comunitaria",
      content:
        "El proyecto Cidaqua promueve la concienciación sobre la importancia del agua, la preservación del patrimonio y la necesidad de una gestión sostenible. A través de programas educativos y actividades de participación comunitaria, se fomenta el compromiso ciudadano y se fortalecen los lazos sociales en la región.",
      icon: "",
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <section className="h-fit w-screen  overflow-x-clip flex  flex-col md:flex-row relative  py-20">
        <div className="w-full z-10 justify-start md:justify-center  flex h-full gap-y-10 flex-col p-4">
          <div className="flex-col-reverse justify-start items-start gap-y-2 flex">
            
            <h1 className=" text-green-600  font-bold  uppercase leading-7">
            Impacto Ambiental
            </h1>
          </div>
          
        </div>

        {/* <MainIcon className="w-full absolute h-fit bottom-40 z-[1] -right-2 md:bottom-1/2 md:translate-y-1/2 md:right-0  aspect-square ml-auto max-w-40 md:max-w-96 " /> */}
        {/* <span className="w-full h-auto right-0 z-0 flex justify-center bottom-0 absolute">
          <svg
            className="w-full max-h-32 sm:max-h-48 md:max-h-full transition-[max-height] min-w-max h-full"
            width="1440"
            height="181"
            viewBox="0 0 1440 181"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 4L288 37L576 45L864 58L1152 0L1440 24V182H1152H864H576H288H0V4Z"
              className="fill-green-600"
            />
            <path
              d="M0 93L288 17L576 44L864 93L1152 69L1440 70V181H1152H864H576H288H0V93Z"
              className="fill-green-400 dark:fill-green-700"
            />
            <path
              d="M0 92L288 102L576 126L864 79L1152 93L1440 128V181H1152H864H576H288H0V92Z"
              className="fill-green-200 dark:fill-green-800"
            />
          </svg>
        </span> */}
      </section>

      <section className=" bg-green-100 dark:bg-green-900  -translate-y-px">
        <div className="">
        <ImageFrame className=" w-full object-bottom" src={FrameImg} alt="Frame" />

          <h2 className="text-center">
          Estrategias de conservación y ahorro de agua
          </h2>
          <p className="text-left ">
          Las estrategias de conservación y ahorro de agua implementadas en el proyecto Cidaqua se centran en maximizar la eficiencia en el uso del agua y garantizar su uso sostenible a largo plazo. A continuación, se presentan algunas de las principales estrategias utilizadas:
          </p>
          <AnimatedAccordionItem color="green" items={accordionStrategies} />

          <p className="text-left">
          Estas estrategias de conservación y ahorro de agua implementadas en Cidaqua tienen como resultado una gestión más eficiente y sostenible de los recursos hídricos en la Huerta de Valencia. Al optimizar el uso del agua, se logra un equilibrio entre las necesidades agrícolas y la preservación de los ecosistemas acuáticos y los recursos naturales, garantizando así la disponibilidad del agua para las generaciones futuras.
          </p>
        </div>
      </section>
      <section className=" -translate-y-px">
        <div className="">
          <h2 className="text-center">
          Monitoreo y mantenimiento de la calidad del agua
          </h2>
          <p className="text-left ">
          El monitoreo y mantenimiento de la calidad del agua en el proyecto Cidaqua se realiza mediante el uso de tecnologías avanzadas que permiten una evaluación precisa y objetiva de la calidad del agua en la Huerta de Valencia. Estas tecnologías garantizan un seguimiento continuo y en tiempo real de los parámetros clave de calidad del agua, y permiten identificar cualquier desviación o anomalía que pueda afectar su idoneidad para diversos usos. A continuación, se presentan las principales tecnologías utilizadas:

          </p>
          <AnimatedAccordionItem color="green" items={accordionPromotion} />

          <p className="text-left">
          Gracias al uso de estas tecnologías avanzadas de monitoreo y mantenimiento de la calidad del agua, Cidaqua puede asegurar que los recursos hídricos de la Huerta de Valencia se mantengan en condiciones óptimas para su uso sostenible y la preservación del ecosistema acuático. La información objetiva y en tiempo real obtenida a través de estas tecnologías ayuda a tomar medidas preventivas y correctivas oportunas, contribuyendo a la protección y conservación a largo plazo del agua en la región.
          </p>
        </div>
      </section>

      <section className=" -translate-y-px">
        <div className="">
          <h2 className="text-center">
          Preservación de espacios naturales y parques asociados a la huerta
          </h2>
          <p className="text-left ">
          La preservación de espacios naturales y parques asociados a la huerta es un objetivo fundamental del proyecto Cidaqua. A través de estrategias y acciones concretas, buscamos proteger y conservar estos espacios naturales, que desempeñan un papel crucial en el equilibrio ecológico de la Huerta de Valencia. A continuación, se presentan los principales enfoques y objetivos de nuestra labor de preservación:

          </p>
          <AnimatedAccordionItem color="green" items={accordionPreservation} />

          <p className="text-left">
          Nuestro objetivo final es asegurar la preservación a largo plazo de los espacios naturales y parques asociados a la huerta, garantizando su valor como hábitats de flora y fauna, espacios recreativos y fuentes de belleza paisajística. A través de un enfoque basado en la sostenibilidad y la conservación, trabajamos para mantener estos espacios como tesoros naturales para las generaciones presentes y futuras.</p>
        </div>
      </section>

      <section className=" -translate-y-px">
        <div className="">
          <h2 className="text-center">
          Flujo ecológico y sostenibilidad en los sistemas de riego
          </h2>
          <p>
                    El flujo ecológico y la sostenibilidad en los sistemas de riego son conceptos fundamentales para garantizar un uso eficiente y responsable del agua en la agricultura y el paisajismo. Estos enfoques buscan minimizar el desperdicio de agua, conservar los recursos naturales y preservar el equilibrio ecológico en los ecosistemas.
                </p>
                <p>
                    El flujo ecológico se refiere a la cantidad y calidad del agua necesaria para mantener los procesos vitales de los ecosistemas acuáticos. En el contexto de los sistemas de riego, implica la asignación adecuada de agua para mantener la salud de los cuerpos de agua y los hábitats asociados. Esto implica no solo satisfacer las necesidades de riego de los cultivos, sino también considerar las demandas hídricas de los ecosistemas naturales y las especies que dependen de ellos.
                </p>
                <p>
                    La sostenibilidad en los sistemas de riego se enfoca en lograr un equilibrio entre las necesidades de producción agrícola y la conservación de los recursos naturales. Esto implica adoptar prácticas de riego eficientes que minimicen las pérdidas de agua, como el uso de tecnologías de riego por goteo, aspersión o microaspersión. Además, se promueven técnicas de gestión del agua, como el monitoreo regular de la humedad del suelo, la programación adecuada de los tiempos y frecuencias de riego, y la reutilización del agua de drenaje.
                </p>
                <p>
                    Para garantizar la sostenibilidad en los sistemas de riego, también es importante considerar el uso de fuentes de agua alternativas, como aguas residuales tratadas o captación de agua de lluvia. Estas prácticas ayudan a reducir la dependencia de los recursos hídricos convencionales y a conservar el agua dulce para otros usos esenciales.
                </p>
        </div>
      </section>

      {/* <section>
        <Contacts />
      </section> */}
    </div>
  );
};

export default Sustainability;
