import { ReactComponent as Logo } from "assets/logo.svg";

import { Link } from "react-router-dom";
import LogoEu from "assets/img/partners/logo-eu.webp";
import LogoGob from "assets/img/partners/logo-gob.webp";
import LogoGeneralitatValenciana from "assets/img/partners/logo-generalitat-valenciana.svg";
import LogoRPTR from "assets/img/partners/logo-rptr.webp";
import LogoIVACE from "assets/img/partners/logo-ivace.webp";

const Footer = () => {
  const navItems = [
    { name: "Inicio", href: "/" },
    { name: "Impacto Social", href: "/social-impact" },
    { name: "Sostenibilidad", href: "/sustainability" },
    { name: "Tecnológica", href: "/technology" },
    { name: "Sobre Nosotros", href: "/about-us" },
    { name: "Contacto", href: "/contact" },
  ];

  const legalItems = [
    { name: "Términos y Condiciones", href: "/terms-and-conditions" },
    { name: "Política de Privacidad", href: "/privacy" },
    { name: "Cookies", href: "/cookies"}
  ];
  return (
    <footer className="bg-gray-950">
      <div className="container max-w-4xl py-10 px-4 mx-auto">
        <div className="flex flex-col md:flex-row">
          <div className="text-left flex flex-col gap-y-2 items-start ">
            <Logo className="w-auto h-8 mb-3 text-white" />
            {/* <div>
              <Link
                to="/dashboard"
                className={` 
                  font-light text-white hover:text-gray-100  whitespace-nowrap cursor-pointer relative z-[1] text-sm`}>
                Dashboard
              </Link>
            </div> */}
            <div className="flex gap-4 flex-wrap">
              {navItems.map((item, index) => (
                <Link
                  to={item.href}
                  className={` 
                  font-light text-white hover:text-gray-100  whitespace-nowrap cursor-pointer relative z-[1] text-sm`}>
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="flex gap-4 flex-wrap">
              {legalItems.map((item, index) => (
                <Link
                  to={item.href}
                  className={` 
                  font-light text-white hover:text-gray-100  whitespace-nowrap cursor-pointer relative z-[1] text-sm`}>
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="flex gap-y-4 w-full flex-col p-2 bg-white rounded-md  justify-between">
            <p className="text-sm ">Financiado por</p>
            <div className="flex items-center justify-start center flex-col md:flex-row gap-y-2 md:gap-y-0 md:gap-x-4 w-full">
              <img
                src={LogoEu}
                alt="Logo de la Unión Europea"
                className="h-12 w-auto md:h-16 object-contain "
              />
              <img
                src={LogoGob}
                alt="Logo de la Unión Europea"
                className="h-12 w-auto md:h-16 object-contain "
              />
              <img
                src={LogoRPTR}
                alt="Logo de la Unión Europea"
                className="h-12 w-auto md:h-16 object-contain "
              />
            </div>

            <p className="text-sm ">En colaboración con</p>
            <div className="flex items-center justify-start center flex-col md:flex-row gap-y-2 md:gap-y-0 md:gap-x-4 w-full">
              <img
                src={LogoGeneralitatValenciana}
                alt="Logo de la Unión Europea"
                className="h-12 w-auto md:h-16 object-contain"
              />
              <img
                src={LogoIVACE}
                alt="Logo de la Unión Europea"
                className="h-12 w-auto md:h-16 object-contain"
              />
            </div>
          </div>
            <p className="text-sm mt-3 text-gray-500">
              © 2024 Cidaqua. Todos los derechos reservados.
            </p>
          </div>
          <div className="text-center md:text-right mt-10 md:mt-0">
            <p className="text-lg opacity-70">
              {/* © 2023 The Tasty Bits. Todos los derechos reservados. */}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
