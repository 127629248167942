const PrivacyPolicy = () => {
  return (
    <section>
      <div>
        <h1>Aviso legal</h1>
        <div className="flex flex-col gap-y-2">
          <p>
            Para dar cumplimiento a la Ley 34/2002, de 11 de julio, de Servicios
            de la Sociedad de la Información y de Comercio Electrónico, y en
            particular, a su artículo 10, se pone a disposición del usuario el
            siguiente aviso legal
          </p>
          <p>
            El acceso, navegación y utilización del sitio web www.cidaqua.com
            (en adelante, el “Sitio Web”) implican la aceptación tácita y sin
            reservas de todas las estipulaciones del presente aviso legal,
            teniendo la misma validez y eficacia que cualquier contrato
            celebrado por escrito y firmado.
          </p>
          <p>
            Su observancia y cumplimiento serán exigibles respecto de cualquier
            persona que acceda, navegue o utilice el Sitio Web. Si usted no está
            de acuerdo con las condiciones expuestas, no acceda, navegue o
            utilice los mismos.
          </p>
        </div>

        <h2>1. IDENTIFICACIÓN</h2>
        <div className="flex flex-col gap-y-2">
          <p>
            Responsable Explotación de los datos: BAUKUNST ARQUITECTURA Y
            PATRIMONIO VIRTUAL SL., representada por D. Adolfo Bartolomé Ibáñez
            Vila, con DNI no 44867848T. con domicilio sito en Calle General
            Sanmartín 7, puerta 11 46004 de Valencia y dirección de correo
            electrónico Hola@cidaqua.com.
          </p>
          <p>
            Responsable de almacenamiento de los datos: BAUKUNST ARQUITECTURA Y
            PATRIMONIO VIRTUAL SL.., con domicilio en 28030 Madrid, Calle
            General Sanmartín 7, puerta 11 no B09737362, y dirección de correo
            electrónico Hola@cidaqua.com.
          </p>
        </div>
        <h2>2. OBJETO</h2>
        <div className="flex flex-col gap-y-2">
          <p>
            El presente aviso legal regula el acceso, navegación y utilización
            del Sitio Web, sin perjuicio de que el Sitio Web se reserve el
            derecho a modificar la presentación, configuración y contenido del
            mismo, así como las condiciones requeridas para su acceso o
            utilización. El acceso y/o utilización del Sitio Web tras la entrada
            en vigor de sus modificaciones o cambios suponen la aceptación de
            los mismos.
          </p>
          <p>
            No obstante, el acceso a determinados contenidos y la utilización de
            determinados servicios puede encontrarse sometido a determinadas
            condiciones particulares, que serán en todo caso claramente
            mostradas y deberán ser aceptadas expresamente por parte de los
            usuarios. Estas condiciones particulares podrán sustituir, completar
            o, en su caso, modificar las establecidas en el presente aviso
            legal.
          </p>
          <p>
            A los efectos de la interpretación del presente aviso legal, se
            entiende que una persona pasa a ser usuaria (en adelante, “Usuario”
            o “Usuarios”) en el momento en que ésta acepta el presente aviso
            legal y la política de privacidad expuestas en el Sitio Web
          </p>
        </div>

        <h2>3. SITIO WEB</h2>
        <div className="flex flex-col gap-y-2">
          <p>
            A través del Sitio Web el Usuario podrá llevar a cabo las siguientes
            acciones:
          </p>
          <p>
            Acceder a la información relativa al Proyecto Cidaqua. -; Conocer
            a las personas físicas o jurídicas que conforman el equipo; Obtener
            información acerca de las redes sociales; Contactar con el soporte a
            través del correo electrónico;
          </p>
        </div>
        <h2>4. ACCESO Y REGISTRO</h2>
        <div className="flex flex-col gap-y-2">
          <p>
            El acceso y navegación por el Sitio Web suponen que el Usuario del
            mismo acepta en su totalidad y se obliga a cumplir por completo el
            presente aviso legal, así como las instrucciones o recomendaciones
            que se le indiquen en cada caso concreto a través del Sitio Web.
          </p>
          <p>
            El acceso a los contenidos del Sitio Web es completamente gratuito.
            Cuando un Usuario facilite datos de carácter personal a través de
            alguno de los formularios habilitados al efecto en el Sitio Web,
            será necesario que previamente acepte la Política de Privacidad.
          </p>
          <p>
            Bajo ningún concepto Cidaqua se responsabilizará de la veracidad
            de los datos facilitados por los Usuarios, por lo que cada uno de
            éstos será el único responsable de las posibles consecuencias,
            errores y fallos que pudieran derivarse de la falta de exactitud de
            los datos.
          </p>
        </div>

        <h2>5. DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL</h2>
        <ol className="list-outside pl-4 list-decimal space-y-3 *:space-y-1">
          <li>
            Derechos sobre el contenido <br />
            <p>

            Baukunst Arquitetura y Patrimonio Virtual S.L es propietario o, en
            su caso, cuenta con las licencias correspondientes sobre los
            derechos de explotación de propiedad intelectual e industrial
            necesarios para operar en el Sitio Web, así como de todos los
            contenidos ofrecidos en el mismo, incluyendo el propio Sitio Web,
            textos, fotografías o ilustraciones, logos, marcas, grafismos,
            diseños, interfaces, o cualquier otra información o contenido, y los
            servicios disponibles a través del mismo.
            </p>
          </li>

          <li>
            Derechos sobre el Sitio Web <br />
            <p>

            En ningún caso se entenderá que el acceso, navegación y utilización
            del Sitio Web por parte del Usuario implica una renuncia,
            transmisión, licencia o cesión total o parcial de dichos derechos
            por parte de Cidaqua. El Usuario dispone de un derecho de uso de
            los contenidos y/o servicios del Sitio Web dentro de un ámbito
            estrictamente doméstico y únicamente con la finalidad de disfrutar
            de las prestaciones del servicio de acuerdo con el presente aviso
            legal. 
            </p>
            <p>

            Las referencias a marcas o nombres comerciales registrados, u
            otros signos distintivos, ya sean propiedad de Cidaqua o de
            terceros, llevan implícita la prohibición sobre su uso sin el
            consentimiento de Cidaqua o de sus legítimos propietarios. En
            ningún momento el acceso, navegación o utilización del Sitio Web y/o
            de sus contenidos confiere al Usuario derecho alguno sobre signos
            distintivos en él incluidos, salvo que se disponga lo contrario en
            el presente aviso legal. 
            </p>
            <p>

            Quedan reservados todos los derechos de
            propiedad intelectual e industrial sobre los contenidos y/o
            servicios del Sitio Web y, en particular, queda prohibido modificar,
            copiar, reproducir, comunicar públicamente, transformar o
            distribuir, por cualquier medio y bajo cualquier forma, la totalidad
            o parte de los contenidos incluidos en el Sitio Web, para cualquier
            fin, si no se cuenta con la autorización previa, expresa y por
            escrito de Cidaqua o, en su caso, del titular de los derechos
            correspondientes.
            </p>
            <p>

            Asimismo, queda prohibido suprimir o manipular las
            indicaciones de copyright u otros créditos que identifiquen a los
            titulares de derechos de los contenidos que el Usuario encuentre en
            el Sitio Web, así como los dispositivos técnicos de protección, las
            huellas digitales, o cualquier mecanismo de protección o información
            incorporada a los contenidos ofrecidos en el Sitio Web. 
            </p>
            <p>

            Los
            servicios ofrecidos, o cualquier información dispuesta a través del
            Sitio Web no podrán ser utilizados para fines comerciales o
            publicitarios sin autorización previa de Cidaqua. En todo caso, el
            Usuario se compromete a no usar el Sitio Web para fines ilegales o
            prohibidos.
            </p>
          </li>
          <li>
            Derechos sobre los contenidos e informaciones difundidas por el
            Usuario <br />
            <p>

            En el caso de que el Usuario envíe información o contenidos de
            cualquier tipo a Cidaqua a través de cualquiera de los canales
            habilitados al efecto, el Usuario declara, garantiza y acepta que
            tiene derecho a hacerlo libremente, que dicha información no
            infringe ningún derecho de propiedad intelectual, industrial,
            secreto comercial o cualesquiera otros derechos de terceros, y que
            dicha información no tiene carácter confidencial ni es perjudicial
            para terceros. 
            </p>
            <p>

            El Usuario reconoce asumir la responsabilidad,
            dejando indemne a Baukunst Arquitectura y Patrimonio Virtual S.L.,
            por cualquier comunicación o contenidos que envíe personalmente o a
            su nombre. Si el Usuario tuviera conocimiento de la existencia de
            algún contenido ilícito, ilegal, contrario a las leyes o que pudiera
            suponer una infracción de derechos de propiedad intelectual,
            industrial, o de cualquier otra índole, deberá notificarlo
            inmediatamente a aukunst Arquitectura y Patrimonio Virtual S.L., a
            través de la dirección de correo electrónico contacto@baukunst.es
            para que éste pueda proceder a la adopción de las medidas oportunas.
            </p>
<p>

            De igual modo, en el caso de que cualquier Usuario o un tercero
            consideren que alguno de los contenidos del Sitio Web propiedad de
            Baukunst Arquitectura y Patrimonio Virtual S.L., vulnera sus
            derechos de propiedad intelectual, industrial, o de cualquier otra
            índole, deberá remitir una comunicación a contacto@baukunst.es con
            la siguiente información: Datos identificativos y medio de contacto
            del reclamante o de su representante legal; Documentación que
            acredite su condición de titular de los derechos supuestamente
            infringidos; 
</p>
            <p>

            Relato detallado de los derechos supuestamente
            infringidos por Baukunst Arquitectura y Patrimonio Virtual S.L. ,
            así como su localización exacta dentro del Sitio Web; Declaración
            expresa por parte del reclamante de que la utilización de los
            contenidos se ha realizado sin el consentimiento del titular de los
            derechos supuestamente infringidos.
            </p>
          </li>
        </ol>

        <h2>6. ENLACES</h2>

        <ol className="list-outside pl-4 list-decimal space-y-3 *:space-y-1">
          <li>
            Enlaces a otras páginas web <br />
            <p>

            En caso de que en el Sitio Web se mostraran enlaces a otras páginas
            web mediante diferentes botones, links, banners o contenidos
            embebidos, Baukunst Arquitectura y Patrimonio Virtual S.L. informa
            de que éstos se encuentran gestionados por terceros, no teniendo
            Baukunst Arquitectura y Patrimonio Virtual S.L. ni medios humanos ni
            técnicos para conocer de forma previa y/o controlar y/o aprobar toda
            la información, contenidos, productos o servicios facilitados por
            otras plataformas a las que se puedan establecer enlaces desde el
            Sitio Web. En consecuencia, Baukunst Arquitectura y Patrimonio
            Virtual S.L. no podrá asumir ningún tipo de responsabilidad por
            cualquier aspecto relativo al Sitio Web o página web a la que se
            pudiera establecer un enlace desde el Sitio Web, en concreto, a
            título enunciativo, pero no limitativo, sobre su funcionamiento,
            acceso, datos, información, archivos, calidad y fiabilidad de sus
            productos y servicios, sus propios enlaces y/o cualquiera de sus
            contenidos, en general. 
            </p>
            <p>

            En este sentido, si los Usuarios tuvieran
            conocimiento efectivo de que las actividades desarrolladas a través
            de estas páginas web de terceros son ilegales o contravienen la
            moral y/o el orden público, deberán comunicarlo inmediatamente a
            Cidaqua a los efectos de que se proceda a deshabilitar el enlace
            de acceso a las mismas, acción que se llevará a cabo en el menor
            tiempo posible. 
            </p>
            <p>

            En cualquier caso, el establecimiento de cualquier
            tipo de enlace desde el Sitio Web a otra página web ajena no
            implicará que exista algún tipo de relación, colaboración o
            dependencia entre Cidaqua y el responsable de dicha página web
            ajena
            </p>
          </li>

          <li>
            Enlaces al canal de Baukunst Arquitectura y Patrimonio Virtual S.L.
            en otras plataformas y redes sociales <br />
            <p>

            
            Baukunst Arquitectura y Patrimonio Virtual S.L. pone a disposición
            de los Usuarios, a través de diferentes herramientas y aplicaciones,
            medios de enlace que permiten a los Usuarios acceder a los canales y
            páginas del Sitio Web que mantiene en diferentes plataformas y redes
            sociales pertenecientes y/o gestionadas por terceros (p.ej. Twitter,
            Facebook, etc.). La inclusión de estos enlaces en el Sitio Web tiene
            por único objeto facilitar a los Usuarios el acceso a dichos canales
            en las diferentes plataformas y redes sociales.
              </p>
            <p>
              El establecimiento
            de estas aplicaciones no implica la existencia de relación alguna
            entre Baukunst Arquitectura y Patrimonio Virtual S.L. y el
            propietario, fabricante o distribuidor del Sitio Web enlazado, como
            tampoco la aceptación y aprobación por parte de Baukunst
            Arquitectura y Patrimonio Virtual S.L. de sus contenidos y/o
            servicios, siendo su propietario, fabricante o distribuidor el único
            responsable de los mismos.</p> 
            <p>

            La activación y uso de estas aplicaciones
            puede conllevar la identificación y autenticación del Usuario
            (login/contraseña) en las plataformas correspondientes,
            completamente externas al Sitio Web y fuera del control de Baukunst
            Arquitectura y Patrimonio Virtual S.L. Al acceder a dichas redes
            externas, el Usuario ingresa en un entorno no controlado por
            Baukunst Arquitectura y Patrimonio Virtual S.L. por lo que Baukunst
            Arquitectura y Patrimonio Virtual S.L. no asumirá ninguna
            responsabilidad sobre la configuración de seguridad de dichos
            entornos. Dado que Baukunst Arquitectura y Patrimonio Virtual S.L.
            puede tener un control limitado sobre el contenido alojado en dichos
            canales, el Usuario reconoce y acepta que Cidaqua no asume
            responsabilidad alguna por el contenido ni por los servicios a los
            que el Usuario pueda acceder en dichas páginas, ni por cualquier
            contenido, productos, servicios, publicidad, ni cualquier otro
            material disponible en los mismos.
            </p>
          </li>
          <li>
            Enlaces en otras páginas web con destino a el sitio web <br />
            <p>

            Baukunst Arquitectura y Patrimonio Virtual S.L. no autoriza el
            establecimiento de un enlace al Sitio Web desde aquellas páginas que
            contengan materiales, información o contenidos ilícitos, ilegales,
            degradantes, obscenos y, en general, que contravengan las leyes, la
            moral o el orden público, o las normas sociales generalmente
            aceptadas. 
            </p>
            <p>

            En todo caso, los Usuarios podrán establecer enlaces que
            dirijan al Sitio Web, siempre y cuando cumplan con las siguientes
            condiciones: 
            </p>
            <p>

            El enlace no podrá reproducir el contenido del Sitio
            Web o partes del mismo de ninguna forma; No está permitido crear un
            browser ni un border environment sobre las secciones del Sitio Web,
            ni de ninguna otra forma podrá modificarse el Sitio Web; No está
            permitido realizar manifestaciones o indicaciones falsas o inexactas
            o incorrectas sobre el Sitio Web y/o, en particular, declarar o dar
            a entender que Baukunst Arquitectura y Patrimonio Virtual S.L. ha
            autorizado el enlace o que ha supervisado o asumido de cualquier
            forma los contenidos o servicios ofrecidos o puestos a disposición
            en la página web en la que se establece dicho enlace; 
            </p>
            <p>

            La página web
            en la que se establezca el enlace al Sitio Web no contendrá
            informaciones o contenidos ilícitos, contrarios a la moral y buenas
            costumbres generalmente aceptadas y al orden público, así como
            tampoco contendrá contenidos contrarios a cualesquiera derechos de
            terceros, incluidos los derechos de propiedad intelectual o
            industrial y/o el derecho al honor, a la intimidad personal o
            familiar o a la propia imagen o de cualquier otro derecho, o
            contenidos contrarios a las normas reguladoras de la protección de
            datos de carácter personal. Baukunst Arquitectura y Patrimonio
            Virtual S.L. no tiene facultad ni medios humanos ni técnicos para
            conocer, controlar ni aprobar toda la información, contenidos,
            productos o servicios facilitados por otras páginas web que tengan
            establecidos enlaces con destino al Sitio Web. Baukunst Arquitectura
            y Patrimonio Virtual S.L. no asume ningún tipo de responsabilidad
            por cualquier aspecto relativo a la página web que establece ese
            enlace con destino al Sitio Web; en concreto, a título enunciativo y
            no limitativo, sobre su funcionamiento, acceso, datos, información,
            archivos, calidad y fiabilidad de sus productos y servicios, sus
            propios enlaces y/o cualquiera de sus contenidos, en general.
            </p>
          </li>
        </ol>
        <h2>7. REGLAS DE USO DEL SITIO WEB</h2>
        <div className="flex flex-col gap-y-2">
          <p>
            No está permitido y, por tanto, sus consecuencias serán de la
            exclusiva responsabilidad del Usuario, el acceso o la utilización
            del Sitio Web con fines ilegales o no autorizados, con o sin ánimo
            de lucro. En particular, y sin que el siguiente listado tenga
            carácter limitativo, queda prohibido:
          </p>
          <p>
            Usar el Sitio Web en forma alguna que pueda provocar daños,
            interrupciones, ineficiencias o defectos en su funcionamiento o en
            los equipos informáticos de un tercero;
          </p>
          <p>
            Usar el Sitio Web para la transmisión, instalación o publicación de
            cualquier virus, código malicioso u otros programas o archivos
            perjudiciales;
          </p>
          <p>
            Usar el Sitio Web para recoger datos de carácter personal de otros
            Usuarios;
          </p>
          <p>
            Usar el Sitio Web de forma ilegal, en contra de la buena fe, la
            moral y el orden público;
          </p>
          <p>
            Registrarse a través del Sitio Web con una identidad falsa,
            suplantando a terceros o utilizando un perfil o realizando cualquier
            otra acción que pueda confundir a otros Usuarios sobre la identidad
            del Usuario;
          </p>
          <p>
            Acceder sin autorización a cualquier sección del Sitio Web, a otros
            sistemas o redes conectados al Sitio Web, a los servidores de
            Cidaqua, o a los servicios ofrecidos a través del Sitio Web, por
            medio de pirateo o falsificación, extracción de contraseñas o
            cualquier otro medio ilegítimo;
          </p>
          <p>
            Llevar a cabo alguna acción que provoque una saturación
            desproporcionada o innecesaria en la infraestructura del Sitio Web o
            en los sistemas o redes de Baukunst Arquitectura y Patrimonio
            Virtual S.L., así como en los sistemas y redes conectados al Sitio
            Web;
          </p>
          <p>
            Impedir el normal desarrollo de un evento, concurso, promoción o
            cualquier otra actividad disponible a través del Sitio Web o
            cualesquiera de sus funcionalidades, ya sea alterando o tratando de
            alterar, ilegalmente o de cualquier otra forma, el acceso,
            participación o funcionamiento de aquéllos, o falseando el resultado
            de los mismos y/o utilizando métodos de participación fraudulentos,
            mediante cualquier procedimiento, y/o a través de cualquier práctica
            que atente o vulnere el presente aviso legal.
          </p>
          <p>
            El incumplimiento de cualquiera de las anteriores obligaciones por
            el Usuario podrá llevar aparejada la adopción por Baukunst
            Arquitectura y Patrimonio Virtual S.L. de las medidas oportunas
            amparadas en Derecho y en el ejercicio de sus derechos u
            obligaciones, pudiendo llegar a la eliminación o bloqueo de la
            cuenta del Usuario infractor, sin que medie posibilidad de
            indemnización alguna por los daños y perjuicios causados.
          </p>
        </div>

        <h2>8. RESPONSABILIDADES Y GARANTÍAS</h2>
        <div className="flex flex-col gap-y-2">
          <p>
            Baukunst Arquitectura y Patrimonio Virtual S.L. no puede garantizar
            la fiabilidad, utilidad o veracidad de toda la información y/o de
            los servicios y contenidos del Sitio Web ni tampoco de la utilidad o
            veracidad de la documentación puesta a disposición a través del
            mismo.
          </p>
          <p>
            En consecuencia, Baukunst Arquitectura y Patrimonio Virtual S.L. no
            garantiza ni se hace responsable de:
          </p>
          <p>
            La continuidad de los contenidos, servicios y/o las funcionalidades
            del Sitio Web;
          </p>
          <p>La ausencia de errores en dichos contenidos;</p>
          <p>
            La ausencia de virus o demás componentes dañinos en el Sitio Web o
            en el servidor que lo suministra;
          </p>
          <p>
            La invulnerabilidad del Sitio Web o la imposibilidad de vulnerar las
            medidas de seguridad que se adopten para el mismo;
          </p>
          <p>
            La falta de utilidad o rendimiento de los contenidos del Sitio Web,
            y;
          </p>
          <p>
            Los daños o perjuicios que cause, a sí mismo o a un tercero,
            cualquier persona que infringiera las condiciones, normas e
            instrucciones que Cidaqua establece, o a través de la vulneración
            de sistemas de seguridad.
          </p>
          <p>
            No obstante, Baukunst Arquitectura y Patrimonio Virtual S.L. declara
            que ha adoptado todas las medidas necesarias, dentro de sus
            posibilidades y del estado de la técnica, para garantizar el
            funcionamiento del Sitio Web y reducir al mínimo los errores del
            sistema, tanto desde el punto de vista técnico como de los
            contenidos publicados en el Sitio Web.
          </p>
          <p>
            Baukunst Arquitectura y Patrimonio Virtual S.L. no garantiza la
            licitud, fiabilidad, o utilidad de los contenidos suministrados por
            terceros a través del Sitio Web. Si el Usuario tuviera conocimiento
            de la existencia de algún contenido ilícito, ilegal, contrario a las
            leyes, o que pudiera suponer una infracción de derechos de terceros,
            deberá notificarlo inmediatamente a Baukunst Arquitectura y
            Patrimonio Virtual S.L. para que éste pueda proceder a la adopción
            de las medidas oportunas.
          </p>
          <p>
            Baukunst Arquitectura y Patrimonio Virtual S.L. no será responsable
            de la veracidad, integridad o actualización de las informaciones
            publicadas en el Sitio Web provenientes de fuentes ajenas al mismo,
            así como tampoco de las contenidas en otras plataformas a las que se
            enlace desde el Sitio Web. Baukunst Arquitectura y Patrimonio
            Virtual S.L. no asumirá responsabilidad en cuanto a hipotéticos
            perjuicios que pudieran originarse por el uso de las citadas
            informaciones.
          </p>
          <p>
            Baukunst Arquitectura y Patrimonio Virtual S.L. no responderá por
            causas ajenas a su control, entre las que pueden enumerarse de
            manera no limitativa: fuerza mayor, problemas de acceso a internet,
            problemas tecnológicos más allá de la gestión diligente y razonable
            de Baukunst Arquitectura y Patrimonio Virtual S.L. acciones u
            omisiones de terceros, etc. En todos los casos referidos, ajenos al
            control y la diligencia debida por Baukunst Arquitectura y
            Patrimonio Virtual S.L. no habrá lugar a indemnización de Baukunst
            Arquitectura y Patrimonio Virtual S.L. al Usuario por daños o
            perjuicios,
          </p>
        </div>
        <h2>9. SUSPENSIÓN DEL SITIO WEB</h2>
        <p>
          Baukunst Arquitectura y Patrimonio Virtual S.L. se reserva el derecho
          a suspender, modificar, restringir o interrumpir, ya sea temporal o
          permanentemente, el acceso, navegación, uso, alojamiento y/o descarga
          del contenido y/o uso de servicios del Sitio Web, con o sin previa
          notificación, a los Usuarios que contravengan cualquiera de las
          disposiciones detalladas en el presente aviso legal, sin que medie la
          posibilidad del Usuario de exigir indemnización alguna por esta causa.
        </p>
        <h2>10. PROTECCIÓN DE DATOS</h2>
        <p>
          De conformidad con lo dispuesto por la Ley Orgánica 3/2018, de 5 de
          diciembre, de Protección de Datos Personales y garantía de los
          derechos digitales, todos los datos de carácter personal recabados
          durante la utilización del Sitio Web serán tratados de conformidad con
          lo dispuesto en la Política de Privacidad, que todo Usuario debe
          aceptar expresamente en caso de facilitar datos de carácter personal a
          través del Sitio Web.
        </p>
        <h2>11. GENERALES</h2>
        <div className="flex flex-col gap-y-2">
          <p>
            Los encabezamientos de las distintas cláusulas son sólo
            informativos, y no afectarán, calificarán o ampliarán la
            interpretación del presente aviso legal. Asimismo, Baukunst
            Arquitectura y Patrimonio Virtual S.L. podrá modificar las
            condiciones aquí estipuladas, total o parcialmente, publicando
            cualquier cambio en la misma forma en que aparece este aviso legal o
            a través de cualquier tipo de comunicación dirigida a los Usuarios.
          </p>
          <p>
            La vigencia temporal del presente aviso legal coincide, por tanto,
            con el tiempo de su exposición, hasta que sea modificado total o
            parcialmente, momento en el cual pasará a tener vigencia el aviso
            legal modificado.
          </p>
          <p>
            Baukunst Arquitectura y Patrimonio Virtual S.L. podrá dar por
            terminado, suspender o interrumpir, en cualquier momento y sin
            necesidad de preaviso, el acceso a los contenidos del Sitio Web, sin
            posibilidad por parte del Usuario de exigir indemnización alguna.
            Tras dicha extinción, seguirán vigentes las prohibiciones de uso de
            los contenidos expuestas anteriormente en el presente aviso legal.
          </p>
          <p>
            En el caso de que cualquier disposición del presente aviso legal
            fuese declarada nula o inaplicable, en su totalidad o en parte, por
            cualquier Juzgado, Tribunal u órgano administrativo competente,
            dicha nulidad o inaplicación no afectará a las restantes
            disposiciones del presente aviso legal.
          </p>
          <p>
            El no ejercicio o ejecución por parte de Cidaqua de cualquier
            derecho o condición contenido en el presente aviso legal no
            constituirá una renuncia al mismo, salvo reconocimiento y acuerdo
            por escrito por su parte.
          </p>
        </div>

        <h2>12. LEGISLACIÓN APLICABLE Y JURISDICCIÓN COMPETENTE</h2>

        <div className="flex flex-col gap-y-2">
          <p>
            La normativa vigente determinará las leyes que deban regir y la
            jurisdicción que deba conocer de las relaciones entre Baukunst
            Arquitectura y Patrimonio Virtual S.L. y los Usuarios del Sitio Web.
            No obstante, siempre que tal normativa prevea la posibilidad para
            las partes de someterse a un fuero determinado, para toda cuestión
            litigiosa derivada o relacionada con el Sitio Web, será de
            aplicación la legislación española vigente en el momento de los
            hechos. Asimismo, Baukunst Arquitectura y Patrimonio Virtual S.L. y
            los Usuarios, con renuncia expresa a cualquier otro fuero que
            pudiera corresponderles, se someten a los Juzgados y Tribunales de
            la ciudad de Valencia (España).
          </p>
          <p>
            Para presentar reclamaciones en el uso de nuestros servicios, puede
            dirigirse por correo electrónico a la siguiente dirección
            contacto@baukunst.es comprometiéndonos a buscar en todo momento una
            solución amistosa del conflicto.
          </p>

          <p>25 de noviembre de 2022.</p>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
