import React, { useState } from "react";

import { InputWithLabel } from "components/molecules/InputWithLabel"
import { Label } from "components/ui/label"
import { Button } from "components/ui/button"
import { Textarea } from "components/ui/textarea"

import { ReactComponent as MapsIcon } from "assets/icons/maps.svg"
import { ReactComponent as PhoneIcon } from "assets/icons/phone.svg"
import { ReactComponent as EmailIcon } from "assets/icons/email.svg"

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    subject: "",
    email: "",
    phone: "",
    message: ""
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: ""
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhone = (phone: string) => {
    const regex = /^[0-9+]+$/;
    return regex.test(phone);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let valid = true;
    let errors = { name: "", email: "", phone: "" };

    if (!formData.name) {
      errors.name = "Nombre es requerido";
      valid = false;
    }

    if (!validateEmail(formData.email)) {
      errors.email = "Correo electrónico no es válido";
      valid = false;
    }

    if (!validatePhone(formData.phone)) {
      errors.phone = "Teléfono solo debe contener números y '+'";
      valid = false;
    }

    setErrors(errors);

    if (valid) {
      // Proceed with form submission
      e.currentTarget.submit();
    }
  };

  return (
    <div>
      <section className="h-fit w-screen  overflow-x-clip flex  flex-col md:flex-row relative  py-20">
        <div className="w-full justify-start md:justify-center z-10 flex h-full gap-y-10 flex-col p-4 ">
          <div className="flex-col-reverse justify-start items-start gap-y-2 flex">
            
            <h1 className=" text-blue-600 font-bold  uppercase leading-7">
              Contacto
            </h1>
          </div>
          <ul className=" *:bg-white/50 *:dark:bg-black/50 *:backdrop-blur-md *:p-2 *:rounded-md grid grid-rows-3 md:grid-cols-3 md:grid-rows-1 gap-4 md:gap-6">
            <li className="flex flex-col">
              <PhoneIcon className="size-8 text-blue-500 " />
              <span className="font-semibold text-lg">
                Telefono de contacto:
              </span>
              <a className="w-fit hover:text-blue-500 transition-colors" href="tel:+34638601304">+34 638 601 304</a>
            </li>
            <li className="flex flex-col">
              <EmailIcon className="size-8 text-blue-500 " />
              <span className="font-semibold text-lg">
              Correo electronico
              </span>
              <a className="w-fit hover:text-blue-500 transition-colors" href="mailto:hola@cidaqua.com">
                {/* // TODO:  Check if hola@cidaqua.com exists */}
                hola@Cidaqua.com
              </a>
            </li>
            <li className="flex flex-col">
              <MapsIcon className="size-8 text-blue-500" />
              <span className="font-semibold text-lg">
              Dirección
              </span>
              <span>
              C/ de Joan Verdeguer, 16, <br />46024 València, Valencia
              </span>
            </li>
          </ul>
        </div>


       
      </section>

      <section className="bg-blue-100 dark:bg-blue-800 -translate-y-px">
        <div className="items-center ">
          {/* TODO: Fix redirect after contact form */}
          <h2>Formulario de contacto</h2>
              <p className="text-center">
              ¡Gracias por tu interés en Cidaqua! Si deseas obtener más información sobre nuestro proyecto o explorar oportunidades de colaboración, puedes comunicarte con nosotros a través de este formulario.
              </p>
          {/* Mail: hola@cidaqua.com */}
          <form onSubmit={handleSubmit}
 className="p-4 md:p-6 -tran slate-y-1/2 shadow-lg bg-white dark:bg-black rounded-lg grid sm:grid-cols-2 gap-4 w-full max-w-3xl" action="https://api.staticforms.xyz/submit" method="post">
            {/* //TODO: Adjust mail to send data to. Replace with accessKey sent to your email */}
            <input type="hidden" name="accessKey" value="70a09db0-1eac-4cf6-be9e-41badb1371fe" />

            <InputWithLabel
              label="Nombre"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={errors.name}
            />
            <InputWithLabel
              label="Asunto"
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
            />
            <InputWithLabel
              label="Correo electrónico"
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={errors.email}
            />
            <InputWithLabel
              label="Teléfono"
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              error={errors.phone}
            />
            <div className="grid w-full items-center gap-2 md:col-span-2">
              <Label htmlFor="message">Mensaje</Label>
              <Textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder=""
              />
            </div>

            {/* If you want replyTo to be set to specific email */}
            {/* <Input type="text" name="replyTo" value="myreplytoemail@example.com" /> */}

            {/* Specify @ as reply to value if you want it to be customer's email */}
            <input type="hidden" name="replyTo" value="@" />

            {/* Optional, Honeypot to prevent spammers */}
            <input className="hidden" type="text" name="honeypot" />

            {/* If you want form to redirect to a specific URL after submission */}
            <input type="hidden" name="redirectTo" value="https://cidaqua.com/contact" />

            {/* Optional */}
            <Button type="submit" variant="ghost" className="md:col-start-2 ml-auto bg-blue-500 hover:bg-blue-300 text-white w-fit">Enviar</Button>
            {/* <input type="submit" value="Submit" /> */}
          </form>
          <p className="text-center">
          Estamos ansiosos por escuchar tus ideas, responder a tus preguntas y explorar cómo podemos trabajar juntos para lograr una gestión sostenible del agua y la preservación del patrimonio en la Huerta de Valencia. ¡Esperamos con entusiasmo tu mensaje!

</p>
        </div>
      </section>
      </div>
  )
}

export default Contact