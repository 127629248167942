import React, { useState, useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "components/ui/accordion";
import Icon from "components/Icon";

type Color = "green" | "blue" | "red" | "yellow" | "gray";


const AnimatedAccordionItem = ({
  items,
  color = "green",
}: {
  color?: Color;
  items: { title: string; content: string; icon?: string }[];

}) => {
  const { ref, inView } = useInView({
    threshold: 0.4, // Adjust threshold as needed
    triggerOnce: true, // Optionally set to true if you want the animation to play only once
  });
  const [animation, setAnimation] = useState("");

  useEffect(() => {
    if (inView) {
      setAnimation("animate-growIn"); // Play animation when in view
    } 
  }, [inView]);

  const colorSets: Record<Color, string> = {
    green: "border-gray-200 dark:border-green-800 data-[state=open]:border-green-200 dark:data-[state=open]:border-green-900 hover:bg-green-100 dark:hover:bg-green-700 data-[state=open]:bg-green-200 dark:data-[state=open]:bg-green-900",
    blue: "border-gray-200 dark:border-blue-800 data-[state=open]:border-blue-200 dark:data-[state=open]:border-blue-900 hover:bg-blue-100 dark:hover:bg-blue-700 data-[state=open]:bg-blue-200 dark:data-[state=open]:bg-blue-900",
    red: "border-gray-200 dark:border-red-800 data-[state=open]:border-red-200 dark:data-[state=open]:border-red-900 hover:bg-red-100 dark:hover:bg-red-700 data-[state=open]:bg-red-200 dark:data-[state=open]:bg-red-900",
    yellow: "border-gray-200 dark:border-yellow-800 data-[state=open]:border-yellow-200 dark:data-[state=open]:border-yellow-900 hover:bg-yellow-100 dark:hover:bg-yellow-700 data-[state=open]:bg-yellow-200 dark:data-[state=open]:bg-yellow-900",
    gray: "border-gray-200 dark:border-gray-800 data-[state=open]:border-gray-200 dark:data-[state=open]:border-gray-900 hover:bg-gray-100 dark:hover:bg-gray-700 data-[state=open]:bg-gray-200 dark:data-[state=open]:bg-gray-900",
  }

  const colorClass = useMemo(() => colorSets[color] || colorSets.green, [color]);


  return (
    <Accordion
    ref={ref}
        defaultValue="item-1"
        className="overflow-clip gap-y-2 flex flex-col"
        type="single"
        collapsible>
            {items.map((item, index) => (
              <AccordionItem
              key={index}
              style={{animationDuration: "0.3s" , animationDelay: `${index * 0.15}s` }}
              className={`${colorClass} *:overflow-visible overflow-clip opacity-0  transition-all border  data-[state=open]:shadow-sm md:py-2 rounded-lg px-4 md:px-8 data-[state=closed]:mx-4 data-[state=open]:pr-8 md:data-[state=open]:pr-12  ${animation}`}
              value={`item-${index + 1}`}>
      <AccordionTrigger className="text-lg decoration-transparent text-left">
        {item.title}
      </AccordionTrigger>
      <AccordionContent  className="flex ">
        {item.content}
        <div className="h-20">
          <Icon
            primaryColor={`${color}-300`}
            secondaryColor={`${color}-400`}
            className=" translate-x-1/2 z-10 scale-150"
            name={item.icon ? item.icon : ""}
            />
        </div>
      </AccordionContent>
    </AccordionItem>
          ))}
    </Accordion>

  );
};

export default AnimatedAccordionItem;
