// Components
import { useEffect, useState, useRef } from "react";
import Icon from "components/Icon";
import { Link } from "react-router-dom";
import { Button } from "components/ui/button";
import ObservableSection from "components/ObservableSection";
import TableOfContents from "components/TableOfContents";
import Divider from "components/sections/Divider";

// sections
import FourPointShowcase from "components/featureSections/FourPointShowcase";
import SwitchableFeatureShowcase from "components/featureSections/SwitchableFeatureShowcase";
import CarouselFeatureSwitcher from "components/featureSections/CarouselFeatureSwitcher";

import Carousel5 from "assets/img/front/hero-bg.jpeg";
import ShowcaseImage from "assets/img/front/showcase_1.jpeg";
import ShowcaseImage2 from "assets/img/front/showcase_2.jpeg";
import CharacteristicsDigitalManagement from "assets/img/characteristics/char-digital-management.webp";
import CharacteristicsSocial from "assets/img/characteristics/char-social.webp";
import CharacteristicsSustainability from "assets/img/characteristics/char-sustainability.webp";
import CharactersiticsRealTime from "assets/img/characteristics/char-real-time.webp";
import Carousel1 from "assets/img/front/carousel-1.jpeg";
import Carousel2 from "assets/img/front/carousel-2.jpeg";
import Carousel3 from "assets/img/front/carousel-3.jpeg";
import HeroBackground from "assets/img/front/img-hero.jpg";
import Stock1 from "assets/iStock-1138665866.jpg";
// @ts-ignore
// import SustainableVideo from "sustainable-video.mp4";
import Stock3 from "assets/img/front/foto-area.jpg";
import LogoLasNaves from "assets/img/partners/logo-las-naves.webp";
import LogoAyuntamiento from "assets/img/partners/logo-ayuntamiento-valencia.svg";
import LogoTheRoot from "assets/img/partners/logo-the-root.webp";
import LogoUnescoValencia from "assets/img/partners/logo-unesco.webp";
import LogoTribunalLasAguas from "assets/img/partners/logo-tribunal-las-aguas.webp";
import AnimatedTabs, { type TabsItem } from "components/molecules/AnimatedTabs";
import { ImageFrame } from "components/ui/ImageFrame";
import AnimatedAccordionItem from "components/molecules/AnimatedAccordionItem";

const Home = () => {
  let [sections, setSections] = useState<string[]>([]);
  let [activeSection, setActiveSection] = useState<string>();

  const tabsItems: TabsItem[] = [
    // {
    //   icon: LogoLasNaves,
    //   key: "lasnaves",
    //   name: "Las Naves",
    //   link: "https://www.lasnaves.com/proyectos/horta-tech/?lang=es",
    //   content:
    //     "Cidaqua fue creado dentro de Col·lab, la aceleradora pública de Las Naves, el centro de innovación del Ayuntamiento de Valencia. Esta colaboración nos proporciona acceso a recursos, experiencia y redes que mejoran el impacto y alcance del proyecto.",
    // },
    // {
    //   icon: LogoAyuntamiento,
    //   key: "ayuntamiento",
    //   name: "Ayuntamient de València",
    //   link: "https://www.valencia.es",
    //   content:
    //     "El “Tribunal de las Aguas de la Vega de Valencia”, más conocido por su denominación abreviada de “Tribunal de las Aguas” es, sin duda alguna, la más antigua de las instituciones de justicia existentes en Europa. Institución a la que vamos a ofrecer nuestro producto disruptivo e innovador para modernizar la gestión del agua de riego y ser una institución pionera en el mundo, aunando impactos ambientales, económicos y sociales. De esta forma podrá tener un control mas profundo y preciso de la gestión del consumo del agua de riego cara a aumentar el ahorro y optimizar las infraestructuras históricas.",
    // },
    {
      icon: LogoUnescoValencia,
      key: "unesco",
      name: "UNESCO Valencia",
      link: "https://valenciamediterraneo.es/",
      content:
        "UNESCO Valencia Centro del Mediterráneo es una institución asociada a la Organización de las Naciones Unidas para la Educación, la ciencia y la Cultura. Responsable por la inclusión del Tribunal de las Aguas en la Lista Representativa del Patrimonio Cultural Inmaterial de la Humanidad.",
    },
    {
      icon: LogoTribunalLasAguas,
      key: "tribunal",
      name: "Tribunal de las Aguas",
      link: "https://www.tribunaldelasaguas.org",
      content:
        "El “Tribunal de las Aguas de la Vega de Valencia”, más conocido por su denominación abreviada de “Tribunal de las Aguas” es, sin duda alguna, la más antigua de las instituciones de justicia existentes en Europa. Institución a la que vamos a ofrecer nuestro producto disruptivo e innovador para modernizar la gestión del agua de riego y ser una institución pionera en el mundo, aunando impactos ambientales, económicos y sociales. De esta forma podrá tener un control mas profundo y preciso de la gestión del consumo del agua de riego cara a aumentar el ahorro y optimizar las infraestructuras históricas.",
    },
    {
      icon: LogoTheRoot,
      key: "theroot",
      name: "We The Root",
      link: "https://wetheroot.com",
      content:
        "The Root es una empresa de tecnología especializada en el desarrollo de soluciones innovadoras para la gestión del agua y la agricultura sostenible. Cidaqua colabora con The Root para implementar tecnologías avanzadas de monitoreo y control en la Huerta de Valencia.",
    },

    // {
    //   icon: LogoSIPAM,
    //   key: "sipam",
    //   name: "Sistema de Información de Patrimonio Agrícola Mundial",
    //   link: "https://www.fao.org/giahs/es/",
    //   content:
    //     "El Sistema de Información de Patrimonio Agrícola Mundial (SIPAM) es una iniciativa de la Organización de las Naciones Unidas para la Alimentación y la Agricultura (FAO) que tiene como objetivo promover y proteger el patrimonio agrícola mundial. SIPAM trabaja en estrecha colaboración con comunidades agrícolas, expertos y organizaciones internacionales para identificar, documentar y conservar las prácticas agrícolas tradicionales y sostenibles que son fundamentales para la seguridad alimentaria y la preservación de la biodiversidad. Cidaqua se enorgullece de ser parte de SIPAM y contribuir a la preservación de la huerta de Valencia y sus técnicas agrícolas tradicionales.",
    // },
    // {
    //   icon: LogoMissionsValencia2030,
    //   key: "missions",
    //   name: "Missions Valencia 2030",
    //   link: "https://www.missionsvalencia.eu/?lang=en",
    //   content:
    //     "UNESCO Valencia Centro del Mediterráneo es una institución asociada a la Organización de las Naciones Unidas para la Educación, la ciencia y la Cultura. Responsable por la inclusión del Tribunal de las Aguas en la Lista Representativa del Patrimonio Cultural Inmaterial de la Humanidad.",
    // },
  ];

  const updateTOC = (isInView: boolean, id: string) => {
    if (isInView) setActiveSection(id);
    else if (!sections.find((s) => s === id)) setSections([...sections, id]);
  };

  const [scrollPosition, setScrollPosition] = useState(0);

  console.log("scrollPosition in home", scrollPosition);

  const [minY, setMinY] = useState(0);
  const [maxY, setMaxY] = useState(window.innerHeight);

  const heroRef = useRef<HTMLElement | null>(null);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const maxScroll = maxY - minY;
    const scrollPercent = Math.min((scrollTop - minY) / maxScroll, 1);
    setScrollPosition(scrollPercent);
  };
  useEffect(() => {
    if (heroRef.current) {
      const rect = heroRef.current.getBoundingClientRect();
      setMinY(rect.top + window.scrollY);
      setMaxY(rect.bottom + window.scrollY);
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  console.log("scrollPosition in home", scrollPosition);

  return (
    <>
      <TableOfContents
        sections={sections}
        activeSection={activeSection!}
        className="fixed bottom-0 right-0 z-50"
      />
      <ObservableSection
        className="h-fit overfl ow-y-clip pt-10 text-white flex-col relative  bg-cover bg-no-repeat pb-8 rou nded-bl-[50%] md:roun ded-br-[50%] rounded-br-none overflow-x-clip"
        //   style={{
        //     backgroundImage: `
        // linear-gradient(
        // 	rgba(0, 0, 0, 0.8),
        // 	rgba(0, 0, 0, 0.6)
        // ),
        // url(${HeroBackground})`,
        //     clipPath: "url(#divider)",
        //   }}
        // onScroll={handleScroll}
        onVisibilityChange={updateTOC}
        id="Inicio">
        <span
          style={{
            backgroundImage: `
        linear-gradient(
          rgba(0, 0, 0, 0.8), 
          rgba(0, 0, 0, 0.6)
        ),
        url(${HeroBackground})`,
            clipPath: "url(#divider)",
          }}
          className="absolute bg-cover bg-no-repeat inset-0 ins et-0 
          "></span>
        <div className="container max-w-6xl z-10 px-4 mx-auto relative mb-10 mt-20 md:mt-28">
          <h1 className="text-balance !leading-tight mb-6 md:max-w-[28ch]">
            Innovación y Sostenibilidad para un Futuro mejor
          </h1>
        </div>
        {/* <span className="absolute backdrop-blur-sm  inset-0 ">

          </span> */}
        {/* <Hero ref={heroRef} scrollPosition={scrollPosition} /> */}
      </ObservableSection>

      <ObservableSection onVisibilityChange={updateTOC} id="Visión">
        <div className="">
          <h2 className="text-center">Visión</h2>
          <p className="text-center md:max-w-3xl mx-auto">
            Cidaqua es una iniciativa destinada a optimizar la gestión de
            recursos hídricos y preservar el patrimonio cultural y natural de la
            Huerta de Valencia. Colaboramos con empresas y organizaciones
            líderes para revolucionar el uso y la conservación del agua mediante
            soluciones tecnológicas innovadoras y la promoción de una economía
            circular.
          </p>
          <ImageFrame src={ShowcaseImage} alt="Visión" />
        </div>
      </ObservableSection>

      <ObservableSection onVisibilityChange={updateTOC} id="Metas & Objetivos">
        <div>
          <h2 className="text-center ">Metas & Objetivos</h2>
          <AnimatedAccordionItem
            color="blue"
            items={[
              {
                title: "Optimizar la Gestión del Agua",
                content:
                  "Lograr la sostenibilidad a largo plazo en la gestión de recursos hídricos.",
              },
              {
                title: "Preservar el Patrimonio Cultural",
                content:
                  "Promover y conservar el patrimonio cultural y natural de la huerta.",
              },
              {
                title: "Crecimiento Social y Económico",
                content:
                  "Fomentar el crecimiento económico, ahorro energético y bienestar social a través de prácticas eficientes de gestión del agua.",
              },
              {
                title: "Conciencia Social",
                content:
                  "Crear conciencia sobre el valor del agua y la importancia histórica de las infraestructuras hidráulicas.",
              },
            ]}
          />
        </div>
      </ObservableSection>
      <ObservableSection
        onVisibilityChange={updateTOC}
        id="Caracteristicas Clave">
        <div>
          <div className="flex flex-col gap-4 lg:px-20 px-4 md:px-8 lg:text-center my-6">
            <h2>Caracteristicas Clave y beneficios</h2>
            <p>
              Cidaqua es una iniciativa ambiciosa y transformadora destinada a
              optimizar la gestión de recursos hídricos y preservar el
              patrimonio cultural y natural de la Huerta de Valencia.
              Colaboramos con empresas y organizaciones líderes para
              revolucionar la gestión del agua de riego.
            </p>
          </div>
          <AnimatedAccordionItem
            color="blue"
            items={[
              {
                title: "Monitoreo y Control en Tiempo Real",
                content:
                  "Utilizamos una solución innovadora de control en tiempo real que permite una gestión eficiente y sostenible de los recursos hídricos. Esta tecnología nos permite optimizar los costos operativos y de mantenimiento, al tiempo que aseguramos el funcionamiento efectivo de la infraestructura hídrica.",
                // icon: "real-time-sensor",
              },
              {
                title: "Gestión Digital del Patrimonio del Agua",
                content:
                  "Cidaqua tiene como objetivo desarrollar herramientas avanzadas para la preservación, difusión y gestión digital del valioso patrimonio humano, paisajístico y cultural de la huerta. A través de la digitalización y los principios de la economía circular, aprovechamos el valor digital del patrimonio hidráulico para promover el turismo cultural y la apreciación entre los ciudadanos.",
                // icon: "digital-patrimony",
              },
              {
                title: "Enfoque Social",
                content:
                  "Cidaqua se esfuerza por crear conciencia en la sociedad sobre el valor del agua y la importancia de los agricultores en la Huerta de Valencia. Promovemos la accesibilidad universal para todos los usuarios y enfatizamos la importancia histórica y patrimonial de las infraestructuras hidráulicas para fomentar un sentido de orgullo y conexión dentro de la comunidad.</li>",
                // icon: "social-focus",
              },
              {
                title: "Sostenibilidad Ambiental",
                content:
                  "Mediante la implementación de tecnologías de sensores basadas en fibra óptica y un panel de control digital integral para la gestión del ciclo del agua, contribuimos activamente a los esfuerzos de conservación del agua. Nuestro objetivo es monitorear la calidad del agua, preservar los espacios naturales y parques asociados a la huerta y establecer sistemas de riego sostenibles que se alineen con los principios de flujo ecológico.</li>",
                // icon: "global-warming",
              },
            ]}
          />
        </div>
      </ObservableSection>
    </>
  );
};

export default Home;
