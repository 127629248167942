import { useState, useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";

import { ReactComponent as LogoCidaqua } from "assets/logo.svg";
import { Button } from "components/ui/button";
import { Link } from "react-router-dom";
import "./Navbar.css";
import Icon from "components/Icon";

const NavBar = () => {
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const [activeItem, setActiveItem] = useState<number | null>(null);

  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [lightMenu, setLightMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const [initialBackgroundStyle, setInitialBackgroundStyle] = useState<{
    width: string;
    left: string;
  }>({ width: "0", left: "0" });

  const location = useLocation();
  const navRefs = useRef<Array<HTMLAnchorElement | null>>([]);

  const navItems = [
    { name: "Inicio", href: "/" },
    { name: "Gestion del Agua", href: "/water-management" },
    { name: "Impacto Social", href: "/social-impact" },
    { name: "Impacto Ambiental", href: "/sustainability" },
    { name: "Tecnología", href: "/technology" },
    { name: "Sobre Nosotros", href: "/about-us" },
    { name: "Contacto", href: "/contact" },
  ];

  function useOutsideAlerter(ref: any) {
    useMemo(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          setOpenMobileMenu(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const updateBackgroundStyle = () => {
    const activeIndex = navItems.findIndex(
      (item) => item.href === location.pathname
    );
    if (navRefs.current[activeIndex]) {
      setInitialBackgroundStyle({
        width: navRefs.current[activeIndex]?.clientWidth + "px" || "0",
        left: navRefs.current[activeIndex]?.offsetLeft + "px" || "0",
      });
    }
  };

  useMemo(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
      console.log("scrolled", scrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const activeIndex = navItems.findIndex(
      (item) => item.href === location.pathname
    );
    setActiveItem(activeIndex);
    updateBackgroundStyle();

    const lightPaths = ["/", "/technology"];
    setLightMenu(lightPaths.includes(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      updateBackgroundStyle();
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseEnter = (index: number) => {
    setHoveredItem(index);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef);

  return (
    <div
      ref={wrapperRef}
      className={`fixed w-full py-4 rounded-b-2xl px-2 md:container top-0 left-1/2 -translate-x-1/2 z-20 flex flex-row items-center transition-all duration-300 
        ${
          scrolled
            ? "bg-white shadow-md text-black dark:bg-gray-900 dark:text-white"
            : lightMenu
            ? "text-white"
            : "text-black dark:text-white"
        }
        
        `}>
      <Link to="/" className="flex items-center">
        <LogoCidaqua
          className={`${
            scrolled ? "text-black" : lightMenu ? "text-white" : "text-blue-900"
          } h-8 xl:h-10`}
        />
      </Link>
      <div className="w-full items-center flex">
        <div className="lg:hidden ml-auto  z-30">
          <Button
            onClick={() => {
              // if scrolled is true and mobileMenu is false, setScrolled to
              if (scrolled && !openMobileMenu) {
                setScrolled(false);
              } else if (openMobileMenu && window.scrollY > 50) {
                setScrolled(true);
              }
              setOpenMobileMenu(!openMobileMenu);
            }}
            variant="default"
            className={`p-2.5 h-auto flex flex-col gap-y-1 ${
              lightMenu ? "bg-gray-950 text-white" : "bg-gray-950 text-white"
            }`}>
            <span className="w-4 h-0.5 block bg-current"></span>
            <span className="w-4 h-0.5 block bg-current"></span>
            <span className="w-4 h-0.5 block bg-current"></span>
          </Button>
        </div>
        <div
          className={`${
            openMobileMenu ? "translate-x-0" : "translate-x-full lg:hidden"
          } py-20 transition-transform w-fit px-4  fixed h-screen bg-white shadow-2xl rounded-l-2xl right-0 top-0 -to p-[23px] z-20`}>
          <nav className="w-full flex-col flex justify-between gap-y-8 items-start">
            <div className="flex flex-col items-start relative flex-grow gap-y-4 w-full rounded-md">
              {navItems.map((item, index) => (
                <Button
                  asChild
                  className={`w-full  ${
                    activeItem === index ? "bg-blue-500" : ""
                  }`}
                  variant="default"
                  key={index}>
                  <Link
                    to={item.href}
                    ref={(el) => (navRefs.current[index] = el)}
                    className={`nav-item 
                      text-center py-2.5 px-5 cursor-pointer relative z-[1] text-sm
                      ${
                        activeItem === index
                          ? lightMenu
                            ? "text-blue-500 "
                            : "text-blue-300 "
                          : lightMenu
                          ? "  hover:!text-blue-500 "
                          : "  hover:!text-blue-500 "
                      }`}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}>
                    {item.name}
                  </Link>
                </Button>
              ))}
            </div>

            <Button asChild variant="default">
            <a href="https://dashboard.cidaqua.com">
                <span className="mr-2">Panel de Control</span>
                <Icon name="dashboard" className="w-4 h-4 text-white" />
            </a>
            </Button>
          </nav>
        </div>

        <nav className="navbar w-full hidden lg:flex justify-between relative px-2.5 gap-4 items-center">
          <div
            className={`flex relative mx-auto  ${
              scrolled
                ? "bg-gray-800/20 outline-3 outline outline-gray-800/20"
                : ""
            }  rounded-md`}>
            {navItems.map((item, index) => (
              <Button
                asChild
                className={`bg-transparent ${lightMenu && "hover:!text-blue-500"} py-1.5 xl:py-2.5 px-3 xl:px-4 2xl:px-5 dark:hover:bg-transparent hover:bg-transparent`}
                variant="ghost"
                key={index}>
                <Link
                  to={item.href}
                  ref={(el) => (navRefs.current[index] = el)}
                  className={`
                    text-center cursor-pointer relative z-[1] text-base
                    ${
                      activeItem === index
                        ? lightMenu
                          ? "text-blue-500"
                          : scrolled
                          ? "text-blue-500"
                          : "text-blue-300"
                        : lightMenu
                        ? "text-current hover:text-blue-500 "
                        : "  hover:!text-blue-500 "
                    }`}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}>
                  {item.name}
                </Link>
              </Button>
            ))}
            <div
              className={`nav-background ${
                hoveredItem === activeItem
                  ? "bg-blue-400"
                  : lightMenu
                  ? "bg-white"
                  : scrolled
                  ? "bg-white"
                  : "bg-gray-900"
              } `}
              style={{
                width:
                  hoveredItem !== null
                    ? navRefs.current[hoveredItem]?.clientWidth + "px"
                    : initialBackgroundStyle.width,
                left:
                  hoveredItem !== null
                    ? navRefs.current[hoveredItem]?.offsetLeft + "px"
                    : initialBackgroundStyle.left,
              }}
            />
          </div>

          <Button asChild className="px-2.5 py-2 h-auto bg-blue-600 hover:bg-blue-500" variant="default">
            <a href="https://dashboard.cidaqua.com">
              <span className="hidden xl:block mr-2">Panel de Control</span>
              <Icon name="dashboard" className="size-5 text-white" />
            </a>
          </Button>
        </nav>
      </div>
    </div>
  );
};

export default NavBar;
